import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NISTHeader from "./NISTHeader";
import NISTSummary from "./NISTSummary";
import NISTSideInfo from "./NISTSideInfo";
import { useInterval } from "../../../hooks/useInterval";
import { useSelector } from "react-redux";
import UnauthorizedView from "../../UI/UnauthorizedView";

const CheckNISTPerms = ({setOverlayActive, setOverlayText}) => {
  const { id } = useParams();
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const permissions = useSelector((state) => state.role.value);
  const user = useSelector((state) => state.user.value);
  const [isNCRTTeamMember, setIsNCRTTeamMember] = useState(false);
  const [isMyNCRT, setIsMyNCRT] = useState(false)

  const checkIfMyNCRT = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/is_my_ncrt/?nist=${id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => {
        if (response?.data?.my_ncrt === true) {
          setIsMyNCRT(true);
        } else {
          setIsMyNCRT(false);
        }
      })
  }, [id])

  useEffect(() => {
    checkIfMyNCRT();
  }, [checkIfMyNCRT]);

  if (permissions.role_type === "Lab" && isMyNCRT) {
    return <NISTDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
  } else if (permissions.role_type === "Validator") {
    return <NISTDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
  } else if (permissions.role_type === "NIAP") {
    return <NISTDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
  } else {
    return <UnauthorizedView />
  }
}

const NISTDetails = ({setOverlayActive, setOverlayText}) => {
  const { id } = useParams();
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const [certReview, setCertReview] = useState({});
  const [isNCRTTeamMember, setIsNCRTTeamMember] = useState(false);
  const user = useSelector((state) => state.user.value);
  const permissions = useSelector((state) => state.role.value);
  const [canEditNist, setCanEditNist] = useState(false);

  const loadCertReview = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}nist/${id}/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setCertReview(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const checkIfTeamMember = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_ncrt_team_member/?user=${user.id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => {
        if (response?.data?.is_member === true) {
          setIsNCRTTeamMember(true);
        } else {
          setIsNCRTTeamMember(false);
        }
      })
      .catch((err) => console.log("unable to get member's virtual teams", err));
  }, [user.id]);

  useEffect(() => {
    loadCertReview();
  }, []);

  useInterval(() => {
    loadCertReview();
  }, 60000); //1 min

  useEffect(() => {
    checkIfTeamMember();
  }, [checkIfTeamMember]);

  useEffect(() => {
    if ((permissions.role_type === "NIAP" || isNCRTTeamMember) && certReview?.stid?.status !== "Approved") {
      setCanEditNist(true);
    } else {
      setCanEditNist(false);
    }
  }, [isNCRTTeamMember, permissions, certReview]);

  return (
    <Container fluid className="my-3">
      <Row>
        <Col sm={12} md={8} lg={9}>
          <NISTHeader certReview={certReview} update={loadCertReview} canEditNist={canEditNist} />
          <NISTSummary
            certReview={certReview}
            update={loadCertReview}
            canEditNist={canEditNist}
            permissions={permissions}
          />
        </Col>
        <Col sm={12} md={4} lg={3}>
          <NISTSideInfo
            certReview={certReview}
            isNCRTTeamMember={isNCRTTeamMember}
            canEditNist={canEditNist}
            permissions={permissions}
            update={loadCertReview}
            setOverlayActive={setOverlayActive} 
            setOverlayText={setOverlayText} 
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CheckNISTPerms;
