import { useEffect, useState, useCallback, useRef } from "react";
import { Button, ListGroup, OverlayTrigger, Tooltip, Form, Popover, Row } from "react-bootstrap";
import ResponsiveMaterialTable from "../../../UI/MaterialTable/ResponsiveMaterialTable";
import { MTableActions } from "material-table";
import { useCookies } from "react-cookie";
import axios from "axios";
import moment from "moment";
import FileDownload from 'js-file-download';
import { useSelector } from "react-redux";
import { useInterval } from "../../../../hooks/useInterval";
import AddEarForm from "./AddEarForm";
import qs from 'qs'
import DeleteModal from "../../../UI/DeleteModal";
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from "react-router-dom";

export default function EARDetailsAccordion({id, setOverlayActive, setOverlayText}) {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    let csrfToken = cookies["csrftoken"];
    const permissions = useSelector((state) => state.role.value);
    const currentUser = useSelector((state) => state.user.value);
    const [groupedEarFiles, setGroupedEarFiles] = useState({});
    const [showButton, setShowButton] = useState(false);
    const [currentEar, setCurrentEar] = useState(true)
    const [selectCurrentEar, setSelectCurrentEar] = useState({})
    const [showDelete, setShowDelete] = useState(false)
    const [show, setShow] = useState(false)
    const tableRef = useRef()
    const [ERTMember, setERTMember] = useState(false);

    const getIsErtMember = useCallback(() => {
        if (currentUser) {
          axios
            .get(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_ert_team_member?user=${currentUser.id}`,
              {
                withCredentials: true,
                headers: { Authorization: `Token ${authToken}` },
              }
            )
            .then((response) => {
              setERTMember(response.data.is_member);
            })
            .catch((err) => console.log("unable to get member's virtual teams", err));
        }
      }, [currentUser]);

    useEffect(() => {
        getIsErtMember();
    }, [getIsErtMember]);

    useInterval(() => {
        getIsErtMember();
    }, 180000); //3 min
    const isValidatorOrEvaluatorProductId = useCallback(()=>{
        // Commented out due to change in requirements
        // All Validators who are Seniors OR Leads can view ears
        if (permissions?.role_type === 'NIAP' || (permissions?.role_type==="Validators" &&( permissions?.is_lead || permissions?.is_senior))) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }, [id])

    const showDeleteModal = (ear) =>{
        setSelectCurrentEar(ear)
        setShowDelete(true)
    }
    const handleCloseDeleteModal = () =>{
        setSelectCurrentEar({})
        setShowDelete(false)
    }

    const onShow = () => {
        setShow(true)
    }

    const onHide = () => {
        setShow(false)
        getEarFiles()
        tableRef.current.onQueryChange()
    }

    useEffect(() => {
        isValidatorOrEvaluatorProductId()
      }, [isValidatorOrEvaluatorProductId]);

    const getEarFiles = useCallback(() => {
        if (id) {
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/get_grouped_ear_files/?product_id=${id}`,
                    {
                        withCredentials: true,
                        headers: { Authorization: `Token ${authToken}` },
                    }
                )
                .then((response) => {
                    setGroupedEarFiles(response.data);
                })
                .catch((error) => {
                    setGroupedEarFiles({});
                });
        }
    }, [id]);

    const handleDeleteEAR = useCallback(()=>{
        const deleteEar = {...selectCurrentEar, 
            status: "Deleted",
            pid: selectCurrentEar?.pid?.product_id,
            creator: selectCurrentEar?.creator?.id,
            editor: currentUser?.id,
            agent: selectCurrentEar?.agent?.id,
            escalator: selectCurrentEar?.escalator?.id,
            response: selectCurrentEar?.response?.upid,
        
        }
        axios.put(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${selectCurrentEar.earid}/`, deleteEar, {
            withCredentials: true,
            headers: {
              "Authorization": `Token ${authToken}`,
              "X-CSRFToken": csrfToken
            },
          }
        ).then(() => {
            tableRef.current.onQueryChange()
        }).catch((error) => {  
            console.log("Unable to delete EAR: " + error)

        })
        
        setShowDelete(false)
    }, [selectCurrentEar])


    useEffect(() => {
        getEarFiles();
    }, [getEarFiles]);

    useInterval(()=>{
        tableRef.current.onQueryChange()
        getEarFiles();
    }, 180000) //3 min

    useEffect(() => {
        tableRef.current.onQueryChange()
    }, [currentEar, id])

    const downloadFile = (file) => {
        setOverlayText("Downloading")
        setOverlayActive(true)
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
            {
                withCredentials: true, 
                headers: {
                    'Authorization':  `Token ${authToken}`,
                },
                responseType: 'blob'
            }).then((response) => {
                setOverlayActive(false)
                FileDownload(response.data, (file.file_new_name ? file.file_new_name : file.file_name))
            }).catch((error) => {
                console.log('Error')
            })
      }

    const columns = [
        {
            title: "EAR ID",
            field: "earid",
            cellStyle: { width: '10%', minWidth: '10%' },
        },
        {
            title: "Submitted On",
            field: "entrydate",
            render: (rowData) =>
                moment.utc(rowData?.entrydate).format("MM/DD/YYYY"),
        },
        {
            title: "Escalated On",
            field:'release_date',
            render: (rowData) =>
                moment.utc(rowData?.entrydate).format("MM/DD/YYYY"),

        },
        {
            title: "Completion Date",
            field: "enddate",
            render: (rowData) =>
                rowData?.enddate ? (
                    moment.utc(rowData?.enddate).format("MM/DD/YYYY")
                ) : (
                    <>TBD</>
                ),
        },
        {
            title: "Status",
            field: "status",
        },
        {
            title: "Files",
            hidden: permissions?.role_type === "Lab",
            render: (rowData) => {
                return <ListGroup className="custom-list-group">
                { groupedEarFiles && groupedEarFiles[rowData?.earid]?.map((file, idx) => (
                  <OverlayTrigger
                  key={idx}
                  placement="top"
                  overlay={<Tooltip>{file.file_label}</Tooltip>}
                >
                    <ListGroup.Item onClick={() => downloadFile(file)} className='text-decoration-underline'>
                      {file.file_label}
                    </ListGroup.Item>
                </OverlayTrigger>
                ))}
              </ListGroup>
            },
        },
        {
            title: "",
            cellStyle: { width: "10%", minWidth: "10%" },
            hidden: !showButton,
            render: (rowData) => (
                <OverlayTrigger trigger="click" rootClose placement='bottom' key='bottom'
                overlay={
                    <Popover>
                        <Popover.Body>
                            <Link to={`/community/ear-reviews/details/${rowData?.earid}`} className='text-decoration-none'>
                                <Button variant='link' className='m-1 p-0 btn-link'><VisibilityIcon color="action" /> View</Button>
                            </Link>
                            { (permissions?.role_permissions?.find((permission) => permission.area === "EAR Reviews") && 
                                permissions?.role_type === "NIAP" || ERTMember  
                                || (permissions?.role_type === "Validator" && rowData?.status === "Draft")
                            )&& 
                              <Button variant='link' className='m-1 p-0 btn-link' onClick={()=>showDeleteModal(rowData)}><DeleteIcon color="action"  /> Delete</Button>
                            }
                        </Popover.Body>
                    </Popover>
                }>
                <Button variant='link'><MoreVertIcon className='float-end' /></Button>
            </OverlayTrigger>
            ),
        },
    ];
    const options = {
        sorting: true,
        search: false,
        columnsButton: true,
        padding: "dense",
        pageSize: 5
    };

    return (
        <>
        <DeleteModal
            show={showDelete}
            selectedEar={selectCurrentEar}
            handleClose={handleCloseDeleteModal}
            type={`EAR ${selectCurrentEar.earid}`}
            deleteFunction={handleDeleteEAR}
        
        />
            <ResponsiveMaterialTable
                columns={columns}
                title={`${currentEar ? "Current" : "Past"} EAR Reviews`}
                tableRef={tableRef}
                data={(query) =>
                    new Promise((resolve, reject) => {
                    // Extract the necessary information from the query object
                    const {
                        page,
                        pageSize,
                        search,
                        filters,
                        orderBy,
                        orderDirection,
                    } = query;
        
                    let newFilters = filters.map((filter) => {
                        let value = ""
                        if (Array.isArray(filter.value)) {
                            value = filter.value
                        } else {
                            value = filter.value.replace(/['"]+/g, '')
                        }
                        return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                    });
                    const params = {
                        offset: page * pageSize,
                        limit: pageSize,
                        search: search,
                        filters: newFilters,
                        orderBy: orderBy?.field,
                        orderDirection: orderDirection,
                        product_id: id,
                        // current_ear: currentEar
                    }
                    axios
                        .get(
                            `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/current_product_ears/?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                            {
                                withCredentials: true,
                                headers: {
                                    Authorization: `Token ${authToken}`,
                                },
                            }
                        )
                        .then((response) => {
                            resolve({
                                data: response.data.results,
                                page: page,
                                totalCount: response.data.count,
                            });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                    })
                    }
                options={options}
                components={{
                    Actions: props => <span>
                        <MTableActions {...props} />
                        {/* <span className="ps-2">
                            <Form.Check 
                                type="switch"
                                inline
                                onChange={() => {setCurrentEar(!currentEar)}}
                                label="Show Past EAR?"
                                checked={currentEar !== true}
                            />
                        </span> */}
                    </span>
                }}
                actions={[
                    {
                    icon: 'add',
                    tooltip: 'Add EAR Review',
                    hidden: permissions?.role_type === "Lab",
                    isFreeAction: true,
                    onClick: onShow
                    }
                ]}
            />
            <AddEarForm show={show} onHide={onHide} product_id={id} getEarFiles={getEarFiles} />
        </>
    );
}
