import { useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Modal, Button, Form, Container, Row, Col } from 'react-bootstrap';
import HTMLEditor from '../../UI/HTMLEditor'
import moment from 'moment';
import {handleAlertAndMailer} from '../../Products/Helper/functions';

export default function ERTApproveRejectModal({
  show,
  handleClose,
  review,
  refetch,
  user,
  complete,
  selectedStatus
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies['csrftoken'];
  let authToken = cookies['auth_token'];
  const [updatedResponse, setUpdatedResponse] = useState({})

  const handleChange = (e) => {
    setUpdatedResponse({
      ...updatedResponse,
      [e.target.name]: e.target.value,
    });
  };

  //update ear status and note1
  const handleUpdateEar = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${review.earid}/`,
        {
          status: selectedStatus,
          gist: updatedResponse?.note1,
          just: updatedResponse?.note1,
          pid: review?.pid?.product_id,
          rejected: complete === false,
          approved: complete === true,
          enddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
        },
        {
          withCredentials: true,
          headers: {
            'X-CSRFToken': csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        handleAlertAndMailer(
          csrfToken, 
          authToken, 
          {
              subject: `EAR Review ${response.data?.earid} for VID ${response.data?.pid} Status: ${response.data?.status}`,
              recipients: {to: ["Lab"]},
              alert_type: "EAR",
              alert_text: `EAR Review ${response.data?.earid} for VID ${response.data?.pid} Status: ${response.data?.status}`,
              alert_type_id: response.data?.earid,
          }
        )
        //if updated response, update official response
        let updatedEar = response?.data
        if(updatedEar?.response !== null) {
          axios
          .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/update/${updatedEar?.response}/`,
            updatedResponse, 
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          })
          .then((response) => {
            refetch();
            handleClose();
          })
          .catch((error) => console.log(error));
        } else {
          axios
          .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/update/`,
            {
              earid: response.data.earid,
              pid: review?.pid?.product_id,
              note1: updatedResponse?.note1,
              creator: user?.id,
              entrydate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            })
            .then((response) => {
              // if new ear update created, we want to update the ear with the new response id
              axios
                .put(
                  `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${review.earid}/`,
                  {
                    response: response?.data?.upid,
                    pid: review?.pid?.product_id,
                  },
                  {
                    withCredentials: true,
                    headers: {
                      'X-CSRFToken': csrfToken,
                      Authorization: `Token ${authToken}`,
                    },
                  }
                )
                .then(response => {
                  refetch();
                  handleClose();
                })
                .catch(error => console.log(error));
            })
            .catch((error) => console.log(error));

        }
      })
      .catch((error) => {
        console.log(error);
      })
  };

  return (
    <Modal
      size='lg'
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Form>
        <Modal.Header closeButton className='border-0'>
          <Modal.Title>
            <h3 className='text-bright-navy'>
              {complete ? "COMPLETE EAR / ERT TEAM" : "REJECT EAR"}
            </h3>
            <h3 className='text-bright-navy'>
              EAR ID REVIEW {review.earid} for VID {review?.pid?.v_id}
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Row>
                <Col>
                  <p className='fw-bold mb-0'>Date of Receipt by the ERT:</p>
                  {review?.release_date != null ?
                    <p>{moment.utc(review?.release_date).format('MM/DD/YYYY')}</p>
                  :
                    <p>No Date Set</p>
                  }
                </Col>
                <Col>
                  <p className='fw-bold mb-0'>Completion Date:</p>
                  <p>{moment.utc(new Date()).format('MM/DD/YYYY')}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='fw-bold mb-0'>Deadline</p>
                  {review?.due != null ?
                    <p>{moment.utc(review?.due).format('MM/DD/YYYY')}</p>
                  :
                    <p>No Date Set</p>
                  }
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <p className='fw-bold mb-0'>Official Response to the Lab:</p>
                  {!complete && <p>(Rejection Rationale)</p>}
                  <HTMLEditor 
                    name="note1" 
                    handleChange={handleChange} 
                    setIsValid={() => {}} 
                    isValid={true} 
                    defaultValue={review?.response?.note1} 
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='light'
            type='button'
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <>
            <Button
              variant={complete ? "success" : "danger"}
              type='button'
              onClick={() => handleUpdateEar()}
            >
              {complete ? "Complete" : "Reject"}
            </Button>
          </>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
