import { Modal, Button, Form } from 'react-bootstrap'
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import HTMLEditor from '../../UI/HTMLEditor';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { handleAlertAndMailer } from "../../Products/Helper/functions"

export default function NotesForm({ show, note_type, currentNote, item, user, handleCloseNotesForm, id, replyTo, refetch }) {

  const [cookies] = useCookies()
  let csrfToken = cookies['csrftoken']
  let authToken = cookies['auth_token']
  const currentUser = useSelector(state => state.user.value);
  const permissions = useSelector(state => state.role.value);
  const [note, setNote] = useState({})
  const [validated, setValidated] = useState(false);
  const [noteValid, setNoteValid] = useState(true);
  const [enforceFocusModal, setEnforceFocusModal] = useState(true)
  const [tq, setTQ] = useState({})

  useEffect(() => {
    if (note_type === "technical_query") {
      getTQ(id)
    }
  }, [show])

  const getTQ = (id) => {
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${id}/`, 
      {withCredentials: true, headers: {'X-CSRFToken': csrfToken, 'Authorization': `Token ${authToken}`}}
    )
    .then((response) => {
      setTQ(response.data)
    })
    .catch(error => console.log("Unable to get TQ: " + error));
  }


  const handleSendTQNoteAddedMail = (sendToLab) => {
    let recipients = {to: ["TRRT Team"], cc: [currentUser?.email]};
    if (sendToLab) {
      recipients = {to: ["TRRT Team"], cc: [currentUser?.email]};
      for (let lab of tq?.product_evaluation) {
        recipients.to.push(lab?.assigned_lab?.poc_email)
      }
    }
    handleAlertAndMailer(
      csrfToken, 
      authToken, 
      {
        alert_type_id: id,
        alert_type: "Technical Query",
        alert_source: "Assurance Maintenance",
        subject: `Technical Query Note Added`,
        recipients: recipients,
        alert_text: `Technical Query Note Added`
      }
    )
  }

  const handleChange = (e) => {
    setNote({...note, [e.target.name]:  e.target.value});
  } 


  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!note.title && !note.note){
      setNoteValid(false)
    }
    else if (form.checkValidity() === false) { // make sure note in html editor is not empty
      e.stopPropagation();
    } else {
      if(currentNote) { 
        let data = {
          ...note,
          submitted_by: note.submitted_by.id,
        }
        if(note.internal_only === true){
          data = {...data, internal_only: "true", visible_to_labs: false, visible_to_validators: false}
        }
        axios.put(`${process.env.REACT_APP_DJANGO_ENDPOINT}note/${currentNote?.note_id}/`, data,
          {withCredentials: true, headers: {'X-CSRFToken': csrfToken, 'Authorization': `Token ${authToken}`}})
          .then(() => handleCloseNotesForm())
          .catch(error => alert("Unable to add note: " + error));
      } else {  
        let data = {
          ...note,
          submitted_by: currentUser.id, 
          submitted_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          note_type: note_type, 
          note_type_id: id, 
          in_response_to: replyTo, 
        }    
        axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}note/`, data, 
          {withCredentials: true, headers: {'X-CSRFToken': csrfToken, 'Authorization': `Token ${authToken}`}})
          .then((response) => {
            setNote({})
            handleCloseNotesForm()
            if (note_type === "technical_query") {
              handleSendTQNoteAddedMail(response.data?.visible_to_labs)
            }
          })
          .catch(error => console.log("Unable to add note: " + error));
      }
    }
    setValidated(true); 
  }

  const showVisibilityOptions = () =>{
    if(note_type === "product"){
      return true
    }else if(note_type === "technical_query"){
      return true
    }else{
      return false
    }
  }

  useEffect(() => {
    setNoteValid(true)
    setValidated(false)
    if (note_type === "technical_query" && !currentNote) {
      //TRRT notes should be visible to SME's and Validators by default
      setNote({"internal_only": false, "visible_to_validators": true, "visible_to_smes": true, "visible_to_labs": false})
    } else if (note_type === 'product' && !currentNote) {
      //product notes should be visible to validators
      setNote({"internal_only": false, "visible_to_validators": true, "visible_to_labs": false })
    } else {
      setNote({...currentNote})    
    }   
  }, [show, currentNote])

  return (
    <Modal size="lg" show={show} onHide={handleCloseNotesForm} enforceFocus={enforceFocusModal}>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          { note_type === 'protection_profile' && <h2>+ NOTE for Protection Profile {item?.pp_id}</h2> }
          { note_type === 'product' && <h2>+ NOTE for VID {item?.v_id}</h2> }
          { note_type === 'assurance_maintenance' && <h2>+ NOTE for AM {item?.title}</h2> }
          { note_type === "technical_query" && <h2>+ NOTE for TQ {item?.tq_id}</h2> }
        </Modal.Header>
        <Modal.Body>
              <Form.Label className='fw-bold'>Submitter: {user}</Form.Label>
              {(
                  note_type !== "technical_query" &&
                  (
                    permissions.role_type === "NIAP" || 
                    permissions.role_type === "Validator" || 
                    (
                      permissions.role_type === "Lab" && 
                      note_type === "product"
                    )
                  )
                ) && 
              <Form.Group controlId='internal_only'>
                <Form.Label className='me-3'>Is Private?*</Form.Label>
                  <Form.Check type="radio" name="internal_only" value={true} inline label="Yes" onChange={handleChange} checked={note?.internal_only === "true" || note?.internal_only ===  true } id="internal_only1" required/>
                  <Form.Check type="radio" name="internal_only" value={false} inline label="No" onChange={handleChange} checked={note?.internal_only === "false" || note?.internal_only === false } id="internal_only2" required/>
              </Form.Group>
              
              }
              {
                  showVisibilityOptions() &&
                  <>
                    <Form.Group className='mb-3' controlId='role_type'>
                      <Form.Label className='me-3'>Visibile To Labs?*</Form.Label>
                      <Form.Check type="radio" name="visible_to_labs" value={true} inline label="Yes" onChange={handleChange} checked={note.visible_to_labs === true || note.visible_to_labs === "true"} id="visible_to_labs1" required/>
                      <Form.Check type="radio" name="visible_to_labs" value={false} inline label="No" onChange={handleChange} checked={note.visible_to_labs === false || note.visible_to_labs === "false"}   id="visible_to_labs2" required/>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='role_type'>
                      <Form.Label className='me-3'>Visibile To Validators?*</Form.Label>
                      <Form.Check type="radio" name="visible_to_validators" value={true} inline label="Yes" onChange={handleChange} checked={note.visible_to_validators === true || note.visible_to_validators === "true"} id="visible_to_validators1" required/>
                      <Form.Check type="radio" name="visible_to_validators" value={false} inline label="No" onChange={handleChange} checked={note.visible_to_validators === false || note.visible_to_validators === "false"}  id="visible_to_validators2" required/>
                    </Form.Group>
                    {note_type === "technical_query" &&
                    <Form.Group className='mb-3' controlId='role_type'>
                      <Form.Label className='me-3'>Visibile To SME's?*</Form.Label>
                      <Form.Check type="radio" name="visible_to_smes" value={true} inline label="Yes" onChange={handleChange} checked={note.visible_to_smes === true || note.visible_to_smes === "true"} id="visible_to_validators1" required/>
                      <Form.Check type="radio" name="visible_to_smes" value={false} inline label="No" onChange={handleChange}  checked={note.visible_to_smes === false || note.visible_to_smes === "false"} id="visible_to_validators2" required/>
                    </Form.Group> 
                    }                   
                  </>
              }
              <Form.Group className='mb-3' controlId='title'>
                <Form.Label className='fw-bold'>{`Title`}</Form.Label>
                <Form.Control type="text" name="title" onChange={handleChange} defaultValue={currentNote?.title} />
              </Form.Group>
              <Form.Group className="mb-3" controlId='note'>
                  <Form.Label className='fw-bold'>Overview:</Form.Label>
                  <HTMLEditor name='note' handleChange={handleChange} setIsValid={setNoteValid} isValid={noteValid} defaultValue={currentNote?.note} setEnforceFocusModal={setEnforceFocusModal}/>
              </Form.Group>
          
        </Modal.Body>
        <Modal.Footer>
              <Button variant="light" type="reset" onClick={() => handleCloseNotesForm()}>Cancel</Button>
              <Button variant="primary" type="submit">{currentNote?.note_id ? 'Update' : 'Add'}</Button>
        </Modal.Footer>
      </Form> 
    </Modal>
  )
}
