import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import Teams from "../Teams";
import { Stack, Accordion, Button, Container, Row, Form } from "react-bootstrap";
import { useCookies } from "react-cookie";
import axios from "axios";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Link } from "react-router-dom";
import CalendarEventCreator from "./CalendarEventCreator";
import { useInterval } from "../../../../hooks/useInterval";
import ModificationHistoryAccordion from "./ModificationHistoryAccordion";
import CertificateReviewAccordion from "./CertificateReviewAccordion";
import GenerateDocAccordion from "./GenerateDoc/GenerateDocAccordion";
import EventLogAccordion from "./EventLogAccordion";
import SrValidatorRecAccordion from "./SrValidatorRecAccordion";
import {
  downloadFile,
  downloadAllFiles,
  getFilesBySourceAndId,
  getPclFiles
} from "../../../UI/Functions/fileFunctions";
import ReactSelect from "../../../ReactSelect";

export default function SideInfo({
  product,
  updateSidebarFiles,
  refetch,
  validationTeam,
  nistReview,
  setOverlayActive,
  setOverlayText
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  const authToken = cookies["auth_token"];
  const [projectPersonnel, setProjectPersonnel] = useState([]);
  const user = useSelector((state) => state.user.value);
  const permissions = useSelector((state) => state.role.value);
  const [checkInFiles, setCheckinFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);
  const [valCheckoutFiles, setValCheckoutFiles] = useState([]);
  const [evalCheckoutFiles, setEvalCheckoutFiles] = useState([]);
  const [pclFiles, setPclFiles] = useState({});
  const [editDates, setEditDates] = useState(false);
  const [updatedDates, setUpdatedDates] = useState({});
  const [calendar, setCalendar] = useState(null);
  const [earFiles, setEarFiles] = useState([]);
  const [validationDates, setValidationDates] = useState({});
  const [editVendor, setEditVendor] = useState(false);
  const [updatedVendor, setUpdatedVendor] = useState({})
  const [updatedOrg, setUpdatedOrg] = useState()
  const navigate = useNavigate();
  const toEmailForm = (toEmail) => {
    navigate("/community/email", { state: { email: toEmail } });
  };

  useEffect(() => {
    setUpdatedOrg(product?.vendor_id)
    const fetchCalendar = async () => {
      const calendarInstance = await CalendarEventCreator({ product, cookies });
      setCalendar(calendarInstance);
    };

    fetchCalendar();
  }, [cookies, product]);

  const fetchEarFiles = useCallback(() => {
    if(product?.product_id){
    axios
        .get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/current_product_ears_files/?product_id=${product?.product_id}&get_latest=true`,
            {
                withCredentials: true,
                headers: { Authorization: `Token ${authToken}` },
            }
        )
        .then((response) => {
            setEarFiles(response.data);
        })
        .catch((error) => console.log(error));
    }
  }, [product.product_id]);

  useEffect(() => {
      fetchEarFiles();
  }, [fetchEarFiles]);

  useEffect(() => {
    const fetchData = async () => {
      if (product?.product_id && authToken) {
        try {
          const evalFiles = await getFilesBySourceAndId(
            product.product_id,
            "Evaluator Check Out",
            authToken
          );
          const valFiles = await getFilesBySourceAndId(
            product.product_id,
            "Validator Check Out",
            authToken
          );
          const checkInFiles = await getFilesBySourceAndId(
            product.product_id,
            "Check In",
            authToken
          );
          const otherFiles = await getFilesBySourceAndId(product.product_id, "Other", authToken);
          const pclFilesData = await getPclFiles(product.product_id, authToken);

          setEvalCheckoutFiles(evalFiles);
          setValCheckoutFiles(valFiles);
          setCheckinFiles(checkInFiles);
          setOtherFiles(otherFiles);
          setPclFiles(pclFilesData);
        } catch (error) {
          console.error("Failed to fetch files", error);
          setEvalCheckoutFiles([]);
          setValCheckoutFiles([]);
          setCheckinFiles([]);
          setOtherFiles([]);
          setPclFiles({});
        }
      }
    };
    fetchData();
  }, [authToken, product?.product_id, updateSidebarFiles]);

  useInterval(() => {
    const fetchIntervalData = async () => {
      if (product?.product_id && authToken) {
        try {
          const evalFiles = await getFilesBySourceAndId(
            product.product_id,
            "Evaluator Check Out",
            authToken
          );
          const valFiles = await getFilesBySourceAndId(
            product.product_id,
            "Validator Check Out",
            authToken
          );
          const checkInFiles = await getFilesBySourceAndId(
            product.product_id,
            "Check In",
            authToken
          );
          const otherFiles = await getFilesBySourceAndId(product.product_id, "Other", authToken);
          const pclFilesData = await getPclFiles(product.product_id, authToken);

          setEvalCheckoutFiles(evalFiles);
          setValCheckoutFiles(valFiles);
          setCheckinFiles(checkInFiles);
          setOtherFiles(otherFiles);
          setPclFiles(pclFilesData);
        } catch (error) {
          console.error("Failed to fetch files during interval", error);
          setEvalCheckoutFiles([]);
          setValCheckoutFiles([]);
          setCheckinFiles([]);
          setOtherFiles([]);
          setPclFiles({});
        }
      }
    };

    fetchIntervalData();
  }, 180000); // 3 min

  const loadPersonnelProductId = useCallback(() => {
    if (product?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/get_all_personnel_by_product_id/?product_id=${product?.product_id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          }
        )
        .then((response) => {
          setProjectPersonnel(response.data);
        })
        .catch((error) => {
          setProjectPersonnel([]);
        });
    }
  }, [product?.product_id, authToken, product, permissions?.role_type, user?.id]);

  useEffect(() => {
    loadPersonnelProductId();
  }, [loadPersonnelProductId]);

  useInterval(() => {
    loadPersonnelProductId();
  }, 180000); //3 min

  const fetchValidationTeamDates = useCallback(() => {
    if (product?.v_id) {
      axios
        .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}lai/lai/identify_validator_dates_by_vid/?vid=${product?.v_id}`, {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        })
        .then((response) => {
          setValidationDates(response.data);
        })
        .catch((error) => {
          setValidationDates({});
        });
    }
  }, [product?.v_id]);

  useEffect(() => {
    fetchValidationTeamDates();
  }, [fetchValidationTeamDates]);

  const fileInfoButton = (file) => {
    return (
      <Stack
        direction="horizontal"
        className="d-flex justify-content-left align-items-start"
        key={file?.file_id}
      >
        {file.file_mime_type === "application/pdf" && (
          <FontAwesomeIcon icon={faFilePdf} color="red" size="xl" />
        )}
        {file.file_mime_type !== "application/pdf" && (
          <FontAwesomeIcon icon={faFileLines} color="gray" size="xl" />
        )}
        <div className="ms-2 text-break">
          <Button
            style={{ overflowWrap: "break-word" }}
            className="text-start m-0 p-0"
            variant="link"
            onClick={() => downloadFile(file, authToken, setOverlayActive, setOverlayText)}
          >
            {
              file.file_display_name 
              ? file.file_display_name 
              : file.file_label
              ? file.file_label
              : file.file_new_name
              ? file.file_new_name
              : file.file_name
            }
          </Button>
          <p className="mb-0 ms-3">{file.file_new_name ? file.file_new_name : file.file_name}</p>
          <p className="mb-0 ms-3">
            Attached by {file?.uploaded_by?.first_name} {file?.uploaded_by?.last_name} on{" "}
            {file?.uploaded_on && moment.utc(file?.uploaded_on).format("YYYY.MM.DD [at] HHmm")}
          </p>
        </div>
      </Stack>
    );
  };

  const handleChange = (e) => {
    setUpdatedDates({ ...updatedDates, [e.target.name]: e.target.value });
  };
  const handleVendorChange  = (e) =>{
    // Product Only
    const vendorFields = ['vendor_poc', 'vendor_email', 'vendor_phone'];
    if (vendorFields.includes(e.target.name)) {
        setUpdatedVendor({...updatedVendor, [e.target.name]: e.target.value})

    }
    // Vendor_id changes
    else {
      setUpdatedOrg({...updatedOrg, [e.target.name]: e.target.value})
    }
  }
  const handleSaveVendor = async () =>{
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product.product_id}/`,
        { ...updatedVendor },
        {
          withCredentials: true,
          headers: { "X-CSRFToken": csrfToken, Authorization: `Token ${authToken}` },
        }
      )
      .then(() => {
        setEditVendor(false);
        refetch();
      })
      .catch((error) => console.log("Unable to update product: " + error));
    await axios
    .put(
      `${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/${product?.vendor_id?.org_id}/`,
      { ...updatedOrg },
      {
        withCredentials: true,
        headers: { "X-CSRFToken": csrfToken, Authorization: `Token ${authToken}` },
      }
    )
    .then(() => {
      setEditVendor(false);
      refetch();
    })
    .catch((error) => console.log("Unable to update Vendor Organization: " + error));
    
  }

  const handleSaveDates = async () => {
    if (updatedDates?.posted_date) {
      await calendar.createCalendarEvent(
        `VID${product?.v_id} Final Package`,
        "Final Package Due",
        new Date(`${updatedDates?.posted_date}T00:00`)
      );
    }
    if (updatedDates?.term_warn_issue_date) {
      const currentDate = new Date(updatedDates?.term_warn_issue_date);
      const sevenDaysBefore = new Date(currentDate);
      sevenDaysBefore.setDate(currentDate.getDate() - 7);
      const formattedSevenDaysBefore = sevenDaysBefore.toISOString();
      await calendar.createCalendarEvent(
        `VID${product?.v_id} Termination Warning Date`,
        "Termination Warning Date",
        new Date(formattedSevenDaysBefore)
      );
      await calendar.createCalendarEvent(
        `VID${product?.v_id} Termination Warning`,
        "Termination Warning",
        new Date(`${updatedDates?.term_warn_issue_date}T00:00`)
      );
    }
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product.product_id}/`,
        { ...updatedDates },
        {
          withCredentials: true,
          headers: { "X-CSRFToken": csrfToken, Authorization: `Token ${authToken}` },
        }
      )
      .then(() => {
        setEditDates(false);
        refetch();
      })
      .catch((error) => console.log("Unable to update product: " + error));
  };
  const vendorInformation = () =>{
    if (editVendor && permissions.role_type === "NIAP"){
      return (
        <Container>
        <Row>
          <Form.Group controlId="name">
            <Form.Label>Vendor Name:</Form.Label>
              <Form.Control
                className="mb-2"
                type="text"
                name="name"
                onChange={handleVendorChange}
                defaultValue={
                  product?.vendor_id?.name}
              />
          </Form.Group>
        </Row>
          <Row>
            <Form.Group controlId="website">
              <Form.Label>Vendor Website:</Form.Label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="website"
                  onChange={handleVendorChange}
                  defaultValue={
                    product?.vendor_id?.website}
                />
            </Form.Group>
          </Row>
          <Row>
            <p className="mb-0 mt-2">POC Information</p>
          </Row>
          <Row>
            <Form.Group controlId="vendor_poc">
              <Form.Label>Vendor POC Name:</Form.Label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="vendor_poc"
                  onChange={handleVendorChange}
                  defaultValue={
                    product?.vendor_poc}
                />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group controlId="vendor_email">
              <Form.Label>Vendor POC Email:</Form.Label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="vendor_email"
                  onChange={handleVendorChange}
                  defaultValue={
                    product?.vendor_email}
                />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group controlId="vendor_phone">
              <Form.Label>Vendor POC Primary Phone:</Form.Label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="vendor_phone"
                  onChange={handleVendorChange}
                  defaultValue={
                    product?.vendor_phone}
                />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="primary_address_1">
              <Form.Label>Vendor Primary Address 1:</Form.Label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="primary_address_1"
                  onChange={handleVendorChange}
                  defaultValue={
                    product?.vendor_id?.primary_address_1}
                />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="primary_address_2">
              <Form.Label>Vendor Primary Address 2:</Form.Label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="primary_address_2"
                  onChange={handleVendorChange}
                  defaultValue={
                    product?.vendor_id?.primary_address_2}
                />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="primary_city">
              <Form.Label>Vendor Primary City:</Form.Label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="primary_city"
                  onChange={handleVendorChange}
                  defaultValue={
                    product?.vendor_id?.primary_city}
                />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="primary_state">
              <Form.Label>Vendor Primary State:</Form.Label>
                {updatedOrg && <ReactSelect
                  options={states}
                  newObj={updatedOrg}
                  handleChange={handleVendorChange}
                  name="primary_state"
                  defaultValue={states?.find(
                    (state) => state?.value === updatedOrg?.primary_state
                  )}
                />}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="primary_zip">
              <Form.Label>Vendor Primary Zip:</Form.Label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="primary_zip"
                  onChange={handleVendorChange}
                  defaultValue={
                    product?.vendor_id?.primary_zip}
                />
            </Form.Group>
          </Row>


        </Container>
      )
    }
    else {
      return (
        <>
          <p className="mb-0 fw-bold">{product?.vendor_id?.name}</p>
            <a href={product?.vendor_id?.website} target="_blank" rel="noreferrer">{product?.vendor_id?.website}</a>
            <p className="mb-0 mt-2">POC Information:</p>
            <p className="mb-0 mx-2">{product?.vendor_poc}</p>
            <p className="mb-0 mx-2">{product?.vendor_email}</p>
            <p className="mb-2 mx-2">{product?.vendor_phone}</p>
            <p className="mb-0">Address:</p>
            <p className="mb-1 mx-2">
              {product?.vendor_id?.primary_address_1}
              {product?.vendor_id?.primary_address_2 && (
                <>
                  {" "}{product.vendor_id.primary_address_2}
                </>
              )}
              {product?.vendor_id?.primary_city && (
                <>
                  {" "}{product.vendor_id.primary_city}
                </>
              )}
              {product?.vendor_id?.primary_state && (
                <>
                  {" "}{product.vendor_id.primary_state},
                </>
              )}
              {product?.vendor_id?.primary_zip && (
                <>
                  {" "}{product.vendor_id.primary_zip}
                </>
              )}
            </p></>
      )
    }
  }

  const projectDates = () => {
    if (editDates && permissions.role_type === "NIAP") {
      return (
        <Container>
          <Row>
            <Form.Group controlId="congrats_letter_date">
              <Form.Label>Congratulatory Letter Date:</Form.Label>
              <Form.Control
                className="mb-2"
                type="date"
                name="congrats_letter_date"
                onChange={handleChange}
                defaultValue={
                  product?.congrats_letter_date &&
                  moment.utc(product?.congrats_letter_date).format("YYYY-MM-DD")
                }
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="term_warn_issue_date">
              <Form.Label>Warning Letter Date:</Form.Label>
              <Form.Control
                className="mb-2"
                type="date"
                name="term_warn_issue_date"
                onChange={handleChange}
                defaultValue={
                  product?.term_warn_issue_date &&
                  moment.utc(product?.term_warn_issue_date).format("YYYY-MM-DD")
                }
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="record_closeout_date">
              <Form.Label>Record Closeout Date:</Form.Label>
              <Form.Control
                className="mb-2"
                type="date"
                name="record_closeout_date"
                onChange={handleChange}
                defaultValue={
                  product?.record_closeout_date && moment.utc(product?.record_closeout_date).format("YYYY-MM-DD")
                }
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="withdrawal_date">
              <Form.Label>Withdrawal Date:</Form.Label>
              <Form.Control
                type="date"
                name="withdrawal_date"
                onChange={handleChange}
                defaultValue={
                  product?.withdrawal_date &&
                  moment.utc(product?.withdrawal_date).format("YYYY-MM-DD")
                }
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="icc_presented_date">
              <Form.Label>Certificate Presented Date</Form.Label>
              <Form.Control
                type="date"
                name="icc_presented_date"
                onChange={handleChange}
                defaultValue={
                  product?.icc_presented_date &&
                  moment.utc(product?.icc_presented_date).format("YYYY-MM-DD")
                }
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="certification_date">
              <Form.Label>Certificate Printed Date</Form.Label>
              <Form.Control
                type="date"
                name="certificate_printed_date"
                onChange={handleChange}
                defaultValue={
                  product?.certificate_printed_date &&
                  moment.utc(product?.certificate_printed_date).format("YYYY-MM-DD")
                }
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="termination_date">
              <Form.Label>Terminated Date:</Form.Label>
              <Form.Control
                type="date"
                name="termination_date"
                onChange={handleChange}
                defaultValue={
                  product?.termination_date &&
                  moment.utc(product?.termination_date).format("YYYY-MM-DD")
                }
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="team_due_date">
              <Form.Label>Validation Team Due Date:</Form.Label>
              <Form.Control
                type="date"
                name="team_due_date"
                onChange={handleChange}
                defaultValue={
                  product?.team_due_date  &&
                  moment.utc(product?.team_due_date).format("YYYY-MM-DD")
                }
              />
            </Form.Group>
          </Row>
        </Container>
      );
    } else {
      return (
        <>
          <p>
            Congratulatory Letter Date:{" "}
            {product?.congrats_letter_date
              ? moment.utc(product?.congrats_letter_date).format("MM/DD/YYYY")
              : "No Date"}
          </p>

          <p>
            Warning Letter Date:{" "}
            {product?.term_warn_issue_date
              ? moment.utc(product?.term_warn_issue_date).format("MM/DD/YYYY")
              : "No Date"}
          </p>
          <p>
            Record Closeout Date:{" "}
            {product?.record_closeout_date
              ? moment.utc(product?.record_closeout_date).format("MM/DD/YYYY")
              : "No Date"}
          </p>
          <p>
            Withdrawal Date:{" "}
            {product?.withdrawal_date
              ? moment.utc(product?.withdrawal_date).format("MM/DD/YYYY")
              : "No Date"}
          </p>
          <p>
            Certificate Presented Date:{" "}
            {product?.icc_presented_date
              ? moment.utc(product?.icc_presented_date).format("MM/DD/YYYY")
              : "No Date"}
          </p>
          <p>
            Certificate Printed Date:{" "}
            {product?.certificate_printed_date
              ? moment.utc(product?.certificate_printed_date).format("MM/DD/YYYY")
              : "No Date"}
          </p>

          <p>
            Terminated Date:{" "}
            {product?.termination_date
              ? moment.utc(product?.termination_date).format("MM/DD/YYYY")
              : "No Date"}
          </p>

          <p>
            Validation Team Due Date:{" "}
            {validationDates?.due
              ? moment.utc(validationDates?.due).format("MM/DD/YYYY")
              : "No Date"}
          </p>
          <p>
            Validation Team Completion Date:{" "}
            {validationDates?.comp
              ? moment.utc(validationDates?.comp).format("MM/DD/YYYY")
              : "No Date"}
          </p>
        </>
      );
    }
  };
  const [states, setStates] = useState([])

  const handleFormVendor = () =>{
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}address/states/get_all_states/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        const states = response.data?.map((data) => {
          return { value: data.us_state_abbrv, label: data.us_state_name };
        });
        setStates(states);
      })
      .catch((error) => console.log(error));
      setEditVendor(true)
  }

  return (
    <div>
      {permissions?.role_type === "NIAP" && (
        <>
          <GenerateDocAccordion product={product} validationTeam={validationTeam} />
          <EventLogAccordion product={product} />
        </>
      )}
      <ModificationHistoryAccordion product={product} />
      {permissions?.role_type !== "Vendor" && (
        <CertificateReviewAccordion nistReview={nistReview} />
      )}
      <Teams projectPersonnel={projectPersonnel} />
      <SrValidatorRecAccordion product={product} />
      <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Project Dates</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            {!editDates && permissions.role_type === "NIAP" && (
              <div className="d-flex justify-content-end">
                <EditIcon color="action" className="clickable" onClick={() => setEditDates(true)} />
              </div>
            )}
            {editDates && (
              <div className="d-flex justify-content-end">
                <SaveIcon color="action" className="clickable" onClick={handleSaveDates} />
              </div>
            )}
            {projectDates()}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Vendor Information</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
          {!editVendor && permissions.role_type === "NIAP" && (
              <div className="d-flex justify-content-end">
                <EditIcon color="action" className="clickable" onClick={() => handleFormVendor()} />
              </div>
            )}
            {editVendor && permissions.role_type === "NIAP" &&(
              <div className="d-flex justify-content-end">
                <SaveIcon color="action" className="clickable" onClick={() =>handleSaveVendor()} />
              </div>
            )}
            {vendorInformation()}
            
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {product?.assigned_lab && (
        <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header className="bg-secondary">
              <h6 className="sidebar-title">CCTL Information</h6>
            </Accordion.Header>
            <Accordion.Body className="bg-light p-1">
              <p className="m-0 fw-bold">{product?.assigned_lab?.name}</p>
              <a href={product?.assigned_lab?.website} target="_blank" rel="noreferrer">{product?.assigned_lab?.website}</a>
              <p className="mb-2">{product?.assigned_lab?.primary_phone}</p>
              <p className="mb-0 mt-2">POC Information:</p>
              <p className="mb-0 mx-2">{product?.assigned_lab?.poc}</p>
              <p className="mb-2 mx-2">{product.assigned_lab.poc_email}</p>
              <p className="mb-0">Address:</p>
              <p className="mb-1 mx-2">
                {product?.assigned_lab?.primary_address_1}
                {product?.assigned_lab?.primary_address_2 && (
                  <>
                    {" "}{product.assigned_lab.primary_address_2}
                  </>
                )}
                {product?.assigned_lab?.primary_city && (
                  <>
                    {" "}{product.assigned_lab.primary_city}
                  </>
                )}
                {product?.assigned_lab?.primary_state && (
                  <>
                    {" "}{product.assigned_lab.primary_state},
                  </>
                )}
                {product?.assigned_lab?.primary_zip && (
                  <>
                    {" "}{product.assigned_lab.primary_zip}
                  </>
                )}
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
        <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header className="bg-secondary">
              <h6 className="sidebar-title">Project Files</h6>
            </Accordion.Header>
            <Accordion.Body className="bg-light p-1">
              {["Certified", "Finalizing", "In Assurance Maintenance"]?.includes(
                product?.status
              ) && (
                <Accordion className="mb-1">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="bg-secondary">
                      <h6 className="text-white">PCL Files</h6>
                    </Accordion.Header>
                    <Accordion.Body style={{ background: "var(--bs-gray-300)" }}>
                      {pclFiles?.eval_files?.map((file) => fileInfoButton(file))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
              <Accordion className="mb-1" defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="bg-secondary">
                    <h6 className="text-white">All Files</h6>
                  </Accordion.Header>
                  <Accordion.Body style={{ background: "var(--bs-gray-300)" }}>
                  <Button as={Link} to={`/community/products/details/${product?.product_id}/product_files`} variant="primary">
                    View All
                  </Button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="mb-1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="bg-secondary">
                    <h6 className="text-white">Evaluator Checkout Files</h6>
                  </Accordion.Header>
                  <Accordion.Body style={{ background: "var(--bs-gray-300)" }}>
                    {evalCheckoutFiles?.map((file) => fileInfoButton(file))}
                    <Button
                      variant="primary"
                      onClick={() => downloadAllFiles(product?.product_id, "Evaluator Check Out", authToken, setOverlayActive, setOverlayText)}
                    >
                      Download All
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="mb-1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="bg-secondary">
                    <h6 className="text-white">Validator Checkout Files</h6>
                  </Accordion.Header>
                  <Accordion.Body style={{ background: "var(--bs-gray-300)" }}>
                    {valCheckoutFiles?.map((file) => fileInfoButton(file))}
                    <Button
                      variant="primary"
                      onClick={() => downloadAllFiles(product?.product_id, "Validator Check Out", authToken, setOverlayActive, setOverlayText)}
                    >
                      Download All
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="mb-1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="bg-secondary">
                    <h6 className="text-white">Evaluator Check-In Files</h6>
                  </Accordion.Header>
                  <Accordion.Body style={{ background: "var(--bs-gray-300)" }}>
                    {checkInFiles?.map((file) => fileInfoButton(file))}
                    {earFiles?.map((file) => fileInfoButton(file))}
                    <Button
                      variant="primary"
                      onClick={() => downloadAllFiles(product?.product_id, "Check In", authToken, setOverlayActive, setOverlayText)}
                    >
                      Download All
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="mb-1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="bg-secondary">
                    <h6 className="text-white">Other Files</h6>
                  </Accordion.Header>
                  <Accordion.Body style={{ background: "var(--bs-gray-300)" }}>
                    {otherFiles?.map((file) => fileInfoButton(file))}
                    <Button variant="primary" onClick={() => downloadAllFiles(product?.product_id, "Other", authToken, setOverlayActive, setOverlayText)}>
                      Download All
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      {product?.assigned_lab && (
        <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header className="bg-secondary">
              <h6 className="sidebar-title">Sync Session</h6>
            </Accordion.Header>
            <Accordion.Body className="bg-light p-1">
              <p className="mb-0 ms-3">Sync Required: {product?.sync_required ? "Yes" : "No"}</p>
              <p className="mb-0 ms-3">
                {" "}
                {product?.sync_required ? `Sync Justification: ${product?.sync_justification}` : ""}
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
      <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">ICCC Interest</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <p className="mb-0 ms-3">
              Vendor Certificate Presentation:{" "}
              {product?.post_to_iccc ? "Present at ICCC" : "No Interest"}
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
