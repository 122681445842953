import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import {
    Container,
    Col,
    Row,
    Button,
    Form,
    Accordion,
    Alert,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import TRRTSideInfo from "./TRRTSideInfo";
import EditIcon from "@mui/icons-material/Edit";
import Select from "react-select";
import reqClassData from "../TRRTSPage/requirementClass.json";
import TQResponseAccordion from "./TQResponseAccordion";
import EscalateToTDModal from "./EscalateToTDModal";
import { Link } from 'react-router-dom'
import parse from "html-react-parser";
import RejectTQModal from "./RejectTQModal";
import Notes from "../../ProtectionProfiles/PPManager/Notes";
import { useInterval } from "../../../hooks/useInterval";
import ReactSelect from "../../ReactSelect";
import TDVoting from "../../TechnicalDecisions/Manager/TDDetailsAccordions/TDVoting";
import { handleAlertAndMailer } from "../../Products/Helper/functions";

export default function TRRTDetailsPage({setOverlayActive, setOverlayText}) {
    const { id } = useParams();
    const [cookies] = useCookies();
    const authToken = cookies["auth_token"];
    let csrfToken = cookies["csrftoken"];
    const user = useSelector((state) => state.user.value)
    const permissions = useSelector((state) => state.role.value);
    const [technicalQuery, setTechnicalQuery] = useState({});
    const [technicalDecisions, setTechnicalDecisions] = useState([]);
    const [editSection, setEditSection] = useState("");
    const [updatedTQ, setUpdatedTQ] = useState({});
    const [showEscalateModal, setShowEscalateModal] = useState(false);
    const [refetchNotes, setRefetchNotes] = useState(false);
    const [showRejectTQModal, setShowRejectTQModal] = useState(false);
    const [alertInfo, setAlertInfo] = useState({message: "", variant: ""})
    const [products, setProducts] = useState([]);
    const [TqHistory, setTqHistory] = useState([])


    const handleCloseRejectTQModal = () => {
        setShowRejectTQModal(false);
    }
    
    const fetchTechnicalQuery = useCallback(() => {
        if (id) {
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${id}/`,
                    {
                        withCredentials: true,
                        headers: { Authorization: `Token ${authToken}` },
                    }
                )
                .then((response) => {
                    setTechnicalQuery(response.data);
                })
                .catch((err) =>
                    console.log("unable to get technical query", err)
                );
        }
    }, [id]);

    const fetchTechnicalDecisions = useCallback(() => {
        if (id) {
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/get_all_tds_by_id/?technical_query=${id}`,
                    {
                        withCredentials: true,
                        headers: { Authorization: `Token ${authToken}` },
                    }
                )
                .then((response) => {
                    setTechnicalDecisions(response.data);
                })
                .catch((err) =>
                    console.log("unable to get technical query", err)
                );
        }
    }, [id]);

    const fetchTechnicalQueryHistory = useCallback(()=>{
        if (id) {
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/history/history_by_tq/?tq_id=${id}`,
                    {
                        withCredentials: true,
                        headers: { Authorization: `Token ${authToken}` },
                    }
                )
                .then((response) => {
                    setTqHistory(response.data)
                })
                .catch((err) =>
                    console.log("unable to get technical query", err)
                );
        }
    }, [id])
    
    useEffect(()=>{
        fetchTechnicalQuery();
    }, [fetchTechnicalQuery])

    useEffect(()=>{
        fetchTechnicalQueryHistory();
    }, [fetchTechnicalQueryHistory])

    useEffect(()=>{
        fetchTechnicalDecisions();
    }, [fetchTechnicalDecisions])

    useInterval(()=>{
        fetchTechnicalQuery();
        fetchTechnicalDecisions();
    }, 60000) //1 min

    const handleCloseEscalateModal = () => {
        setShowEscalateModal(false);
        fetchTechnicalQuery();
        fetchTechnicalDecisions();
        setRefetchNotes(!refetchNotes);
    };

    const handleChange = (e) => {
        setUpdatedTQ({ ...updatedTQ, [e.target.name]: e.target.value });
        if (e.target.type === "checkbox") {
            axios
                .put(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${id}/`,
                    {
                        require_pp_action: e.target.checked,
                        recaptcha_token: "member_portal",
                        modified_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()))
                    },
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Token ${authToken}`,
                            "X-CSRFToken": csrfToken,
                        },
                    }
                )
                .then((response) => {
                    fetchTechnicalQuery();
                })
                .catch((error) => {
                    console.log("error updating TQ details", error);
                });
        }
    };

    const handleUpdateStatus = (status) => {
        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${id}/`,
                {
                    status: status,
                    recaptcha_token: "member_portal",
                    modified_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()))
                },
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Token ${authToken}`,
                        "X-CSRFToken": csrfToken,
                    },
                }
            )
            .then((response) => {
                fetchTechnicalQuery();
                //we send accept email here
                handleAlertAndMailer(
                    csrfToken, 
                    authToken, 
                    {
                      alert_type_id: technicalQuery?.tq_id,
                      alert_type: "Technical Query",
                      subject: `Technical Query ${status}`,
                      recipients: { to: ["Customer"], cc: [] },
                      alert_text: `Technical Query ${status}, TQ ID: ${technicalQuery?.tq_id}`,
                    }
                  )
            })
            .catch((error) => {
                console.log("error updating TQ details", error);
            });
    };

    const handleSave = (update) => {
        const modifier = {};
        modifier["modified_by"] = user?.id;
        modifier["modified_on"] = new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()));
        axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/history/`,
        {
          tq_id: id,
          reason_for_update: update
        },
        {
          withCredentials: true,
          headers: { "X-CSRFToken": csrfToken, Authorization: `Token ${authToken}` },
        }
      )
      .then(() => {
      })
      .catch((error) => console.log(error.response.data));

        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${id}/`,
                {
                    ...updatedTQ,
                    ...modifier,
                    recaptcha_token: "member_portal",
                },
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Token ${authToken}`,
                        "X-CSRFToken": csrfToken,
                    },
                }
            )
            .then((response) => {
                setEditSection("");
                setUpdatedTQ({});
                setAlertInfo({message:"Succesfully Saved Technical Query Information", variant:"success"})
                fetchTechnicalQuery();
            })
            .catch((error) => {
                console.log("error updating TQ details", error);
            });
    };
    const loadProduct = ()=> { 
        axios
          .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_all_products_not_certified_or_in_am/`)
          .then((response) => {
            setProducts(response.data);
          })
          .catch((error) => { 
            console.log('Error getting Projects: ', error);
          });
    }
    useEffect(() => {
        loadProduct()
      }, []);
    const productOptions = [
        ...products?.map((product) => ({
          value: product.product_id,
          label: `VID${product.v_id}`,
        }))
      ];

    return (
        <Container fluid>
            {alertInfo.message !== "" &&
                <Row className='m-3 mb-3'>
                <Alert className="mb-0" variant={alertInfo.variant} dismissible onClose={() => setAlertInfo({message: "", variant: ""})} >
                    {alertInfo.message}
                </Alert>
                </Row>
            }
            <RejectTQModal 
                show={showRejectTQModal} 
                handleClose={handleCloseRejectTQModal} 
                technicalQuery={technicalQuery} 
                refetch={fetchTechnicalQuery}
            />
            <EscalateToTDModal
                show={showEscalateModal}
                handleClose={handleCloseEscalateModal}
                technicalQuery={technicalQuery}
                setAlertInfo={setAlertInfo}
            />
            <Row>
                <Col sm={12} md={8} lg={9} className="p-0">
                    <Row className="mt-3 mb-4">
                        <Col sm={12} md={4}>
                            <h3 className="text-primary">
                                TECHNICAL QUERY {id}
                            </h3>
                        </Col>
                        {!["TD Required","Resolved","Rejected"]?.includes(technicalQuery?.status) &&
                            permissions.role_permissions.find(
                                (permission) =>
                                    permission.area === "Technical Questions"
                            )?.update && (
                                <>
                                {technicalQuery?.status === "Proposed" && 
                                    <Col md={12} lg={2}>
                                        <Button
                                            variant="danger"
                                            className="rounded-pill"
                                            onClick={() =>
                                                setShowRejectTQModal(true)
                                            }
                                        >
                                            Reject
                                        </Button>
                                    </Col>}
                                    {technicalQuery?.status === "Proposed" &&
                                    <Col md={12} lg={2}>
                                        <Button
                                            variant="success"
                                            className="rounded-pill"
                                            onClick={() =>
                                                handleUpdateStatus('Accepted')
                                            }
                                        >
                                            Accept
                                        </Button>
                                    </Col>
                                    }
                                    {technicalQuery?.status === "Accepted" &&
                                    <Col md={12} lg={2}>
                                        <Button
                                            variant="primary"
                                            className="rounded-pill"
                                            onClick={() =>
                                                setShowEscalateModal(true)
                                            }
                                        >
                                            Escalate
                                        </Button>
                                    </Col>
                                    }
                                </>
                            )}
                        {permissions.role_permissions.find(
                            (permission) =>
                                permission.area === "Technical Questions"
                        )?.update &&
                            (
                                <Col md={12} lg={4}>
                                    <Form>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            name="require_pp_action"
                                            label="Required Action in Next PP Update"
                                            checked={
                                                technicalQuery?.require_pp_action
                                            }
                                            onChange={handleChange}
                                        />
                                    </Form>
                                </Col>
                            )}
                    </Row>
                    <Row className="border-bottom border-dark me-1">
                        <Col className="border-end border-dark">
                            <h3 className="fw-bold">{technicalQuery?.title}</h3>
                        </Col>
                        <Col>
                            {permissions.role_permissions.find(
                                (permission) =>
                                    permission.area === "Technical Questions"
                            )?.update && permissions.role_type === "NIAP" &&
                                <Row>
                                    <Col className="d-flex justify-content-end">
                                        <EditIcon
                                            fontSize="small"
                                            className="clickable"
                                            onClick={() =>
                                                editSection ===
                                                "Associated Products"
                                                ? setEditSection("")
                                                : setEditSection("Associated Products")
                                            }
                                        />
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <h4>Associated Projects in Evaluation</h4>
                            </Row>
                            {editSection === "Associated Products" ?
                            <>
                            <Row>
                                <Form.Group
                                    className="mb-3"
                                    controlId="Associated Products"
                                >
                                    <ReactSelect 
                                        isMulti={true}
                                        options={productOptions} 
                                        newObj={updatedTQ} 
                                        handleChange={handleChange} 
                                        name={'product_evaluation'} 
                                        isValid={true} 
                                        />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col className="w-auto d-flex justify-content-end">
                                    <Button
                                        variant="success"
                                        onClick={() => handleSave("Updated Associated Projects")}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                            </>
                            :
                            <Row>
                                {technicalQuery?.product_evaluation?.map(
                                    (tq, idx) => (
                                        <Col key={idx}>
                                        <Link 
                                            to={`/community/products/details/${tq?.product_id}/`} 
                                            className='text-decoration-none'
                                            key={idx}
                                        >
                                            <h5 className="text-decoration-underline">
                                                VID{tq?.v_id}
                                            </h5>
                                        </Link>   
                                        </Col>

                                    )
                                )}
                            </Row>
                            }
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-3">
                        <Col md={12} lg={3} className="border-end">
                        {permissions.role_permissions.find(
                            (permission) =>
                                permission.area === "Technical Questions"
                        )?.update &&
                            <Row>
                                <Col className="d-flex justify-content-end">
                                    <EditIcon
                                        fontSize="small"
                                        className="clickable"
                                        onClick={() =>
                                            editSection ===
                                            "status"
                                            ? setEditSection("")
                                            : setEditSection("status")
                                        }
                                    />
                                </Col>
                            </Row>
                        }
                            <Row>
                                <h5>Status</h5>
                            </Row>
                            {editSection === "status" ?
                            <>
                            <Row>
                                <Form.Group
                                    className="mb-3"
                                    controlId="status"
                                >
                                    <Form.Select
                                        name="status"
                                        defaultValue={
                                            technicalQuery?.status
                                        }
                                        onChange={handleChange}
                                    >
                                        <option value="">Select...</option>
                                        {[
                                            "Proposed", 
                                            "In Progress", 
                                            "TD Required", 
                                            "TD Proposed", 
                                            "Resolution Proposed", 
                                            "Resolved",
                                        ]?.map((s, idx) => (
                                            <option key={idx} value={s}>
                                            {s}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col className="w-auto d-flex justify-content-end">
                                    <Button
                                        variant="success"
                                        onClick={()=>handleSave("Status Updated")}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                            </>
                            :
                            <Row>
                                <h5 className="text-secondary">
                                    {technicalQuery?.status}
                                </h5>
                            </Row>
                            }
                        </Col>
                        <Col md={12} lg={5} className="border-end">
                            {permissions.role_permissions.find(
                                (permission) =>
                                    permission.area === "Technical Questions"
                            )?.update &&
                                (!["TD Required", "Resolved", "Proposed"].includes(technicalQuery?.status) &&
                                    <Row>
                                        <Col className="d-flex justify-content-end">
                                            <EditIcon
                                                fontSize="small"
                                                className="clickable"
                                                onClick={() =>
                                                    editSection ===
                                                    "requirement"
                                                        ? setEditSection("")
                                                        : setEditSection(
                                                              "requirement"
                                                          )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                )}
                            <Row>
                                {editSection === "requirement" ? (
                                    <>
                                        <Col xs={4} className="p-3">
                                            <h5>Requirement: </h5>
                                        </Col>
                                        <Col xs={8} className="mt-2">
                                            <Form.Group controlId="react-select-req-input">
                                                <Select
                                                    defaultValue={reqClassData.find(
                                                        (option) =>
                                                            option.value ===
                                                            technicalQuery?.requirement
                                                    )}
                                                    options={reqClassData}
                                                    onChange={(e) =>
                                                        handleChange({
                                                            target: {
                                                                name: "requirement",
                                                                value: e.value,
                                                            },
                                                        })
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </>
                                ) : (
                                    <h5>
                                        Requirement:{" "}
                                        <span className="text-secondary">
                                            {technicalQuery?.requirement}
                                        </span>
                                    </h5>
                                )}
                            </Row>
                            <Row>
                                {editSection === "requirement" ? (
                                    <>
                                        <Col xs={4} className="p-3">
                                            <h5>Family/ Component/ Element:*</h5>
                                        </Col>
                                        <Col xs={8} className="mt-2">
                                            <Form.Group
                                                className="mb-3"
                                                controlId="family_component_element"
                                            >
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name="family_component_element"
                                                    onChange={handleChange}
                                                    defaultValue={
                                                        technicalQuery?.family_component_element
                                                    }
                                                    placeholder="Enter a Family/Component/Element"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </>
                                ) : (
                                    <h5>
                                        Family/Component/Element:{" "}
                                        <span className="text-secondary">
                                            {
                                                technicalQuery?.family_component_element
                                            }
                                        </span>
                                    </h5>
                                )}
                            </Row>
                            {editSection === "requirement" && (
                                <Row>
                                    <Col className="w-auto d-flex justify-content-end">
                                        <Button
                                            variant="success"
                                            onClick={()=>handleSave("Updated Family/Component/Element")}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                        <Col sm={12} md={4}>
                            {permissions.role_permissions.find(
                                (permission) =>
                                    permission.area === "Technical Questions"
                            )?.update &&
                                (!["TD Required", "Resolved", "Proposed"].includes(technicalQuery?.status)) && (
                                    <Row>
                                        <Col className="d-flex justify-content-end">
                                            <EditIcon
                                                fontSize="small"
                                                className="clickable"
                                                onClick={() =>
                                                    editSection === "priority"
                                                        ? setEditSection("")
                                                        : setEditSection(
                                                              "priority"
                                                          )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                )}
                            <Row>
                                <h5>Requested Priority</h5>
                            </Row>
                            <Row>
                                {editSection === "priority" ? (
                                    <Form.Group
                                        className="mb-3"
                                        controlId="urgency"
                                    >
                                        <Form.Select
                                            name="urgency"
                                            defaultValue={
                                                technicalQuery?.urgency
                                            }
                                            onChange={handleChange}
                                        >
                                            <option value="">Select...</option>
                                            <option value="1. Delaying an Evaluation">
                                                1. Delaying an Evaluation
                                            </option>
                                            <option value="2. On-going Evaluation">
                                                2. On-going Evaluation
                                            </option>
                                            <option value="3. Project in Pre-Eval or General">
                                                3. Project in Pre-Eval or
                                                General
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                ) : (
                                    <h5 className="text-danger">
                                        {technicalQuery?.urgency}
                                    </h5>
                                )}
                            </Row>
                            {editSection === "priority" && (
                                <Row>
                                    <Col className="w-auto d-flex justify-content-end">
                                        <Button
                                            variant="success"
                                            onClick={()=>handleSave("Updated Priority")}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Accordion defaultActiveKey={"1"}>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className="border-0">
                                        <h6 className="text-primary fw-bold">
                                            QUESTION(s)
                                        </h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {technicalQuery?.question && (
                                            <>
                                                {parse(
                                                    technicalQuery?.question
                                                )}
                                            </>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion defaultActiveKey={"1"}>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <h6 className="text-primary fw-bold">
                                            OPTIONS EXPLORED
                                        </h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {technicalQuery?.previously_expolored && (
                                            <>
                                                {parse(
                                                    technicalQuery?.previously_expolored
                                                )}
                                            </>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion defaultActiveKey={"1"}>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <h6 className="text-primary fw-bold">
                                            IMPACT PER THE CUSTOMER:
                                        </h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {technicalQuery?.impact && (
                                            <>{parse(technicalQuery?.impact)}</>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {technicalQuery?.status !== "Proposed" &&
                            <TQResponseAccordion
                                technicalQuery={technicalQuery}
                                refetch={fetchTechnicalQuery}
                            />
                            }
                            <Accordion>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <h6 className="text-primary fw-bold">
                                            TECHNICAL DECISIONS:
                                        </h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    {technicalDecisions?.map(
                                        (td, idx) => (
                                            <Accordion className='mb-1' key={idx}>
                                                <Accordion.Item eventKey="1" key={idx}>
                                                    <Accordion.Header>
                                                        <Row className="w-100">
                                                            <Col xs={6} md={3}>
                                                                <Button
                                                                    variant="link"
                                                                    className="m-0 p-0 text-decoration-underline "
                                                                    href={`/community/technical-decisions/details/${td?.identifier}`}
                                                                >
                                                                    <h5>{td?.identifier}</h5>                                                                     
                                                                </Button>
                                                            </Col>
                                                            <Col xs={6} md={3}>
                                                                <h5>{td?.title}</h5>
                                                            </Col>
                                                            {td?.status === "TD Proposed" && (
                                                                <TDVoting techDecision={td} refetch={fetchTechnicalDecisions} currentUser={user}/>   
                                                            )}
                                                        </Row>
                                                    </Accordion.Header>
                                                    <Accordion.Body style={{ background: 'var(--bs-gray-300)'}}>
                                                        <Row>
                                                            <Col>  <h6>Description:</h6></Col>
                                                            <Col> <p> {td?.description && parse(td?.description)}</p></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col><h6>Justification:</h6></Col>
                                                            <Col><p>{td?.justification && parse(td?.justification)}</p></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col><h6>Resolution:</h6></Col>
                                                            <Col><p>{td?.resolution && parse(td?.resolution)}</p>
                                                            </Col>
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        )
                                    )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {(permissions.role_type === "NIAP" || permissions.role_type === "Validator" || permissions.role_type === "Lab" || permissions.role_type === "SME") &&
                            <Notes 
                                item={technicalQuery}
                                note_type="technical_query"
                                user={user}
                                id={id}
                                refetchNotes={refetchNotes}
                            />
                            }  
                            <Accordion defaultActiveKey={"1"}>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className="border-0">
                                        <h6 className="text-primary fw-bold">
                                            HISTORY
                                        </h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    {TqHistory?.map((history, idx) => (
                                        <Container key={idx}>
                                            <Row>
                                                <Col className="text-start">
                                                    <h5>
                                                        Updated By: {history?.updated_by?.first_name} {history?.updated_by?.last_name}
                                                    </h5>
                                                </Col>
                                                <Col className="text-end">
                                                    <h5>
                                                        Updated On:{" "}
                                                        {history?.updated_on
                                                            ? moment.utc(history?.updated_on).format("YYYY.MM.DD [at] HH:mm")
                                                            : "N/A"}
                                                    </h5>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h5>Reason For Update</h5>
                                                    <p>{history?.reason_for_update}</p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>                     
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} md={4} lg={3}>
                    <TRRTSideInfo
                        technicalQuery={technicalQuery}
                        TqHistory={TqHistory}
                        refetch={fetchTechnicalQuery}
                        handleCloseModal={handleCloseEscalateModal}
                        setAlertInfo={setAlertInfo}
                        setOverlayActive={setOverlayActive} 
                        setOverlayText={setOverlayText} 
                    />
                </Col>
            </Row>
        </Container>
    );
}
