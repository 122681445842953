import { useEffect, useState, useCallback } from "react";
import progressPointInfo from "./ProgressPointData";
import { useSelector } from "react-redux";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Modal, Button, Form, Container, Row, Col, Stack, Alert } from "react-bootstrap";
import moment from "moment";
import HTMLEditor from "../../UI/HTMLEditor";
import FileDownload from "js-file-download";
import "./ProgressPointModal.css";
import { handleAlertAndMailer } from "../Helper/functions";

export default function ProgressPointModal({
  show,
  handleClose,
  product,
  setProduct,
  protectionProfiles,
  progressPoint,
  updateProgress,
  readOnly,
  allFiles,
  isEdit,
  isValidator,
  refetch,
  setRefetchNotes,
  setOverlayActive,
  setOverlayText
}) {
  const [cookies] = useCookies();
  const user = useSelector((state) => state.user.value);
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [formInfo, setFormInfo] = useState({});
  const [files, setFiles] = useState([]);
  const [nist, setNist] = useState({});
  const [nistFiles, setNistFiles] = useState([]);
  const [newNistFiles, setNewNistFiles] = useState([]);
  const [checkoutFiles, setCheckoutFiles] = useState([]);
  const [newCheckoutFiles, setNewCheckoutFiles] = useState([]);
  const [rejected, setRejected] = useState(false);
  const currentUser = useSelector((state) => state.user.value);
  const permissions = useSelector((state) => state.role.value);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [editedFileLabel, setEditedFileLabel] = useState("");
  const [nistEditedFileLabel, setNistEditedFileLabel] = useState("");
  const [completionDateIds, setCompletionDateIds] = useState([]);
  const [submissionDateIds, setSubmissionDateIds] = useState([]);
  const [isPsd, setIsPsd] = useState(false);
  const [eventId, setEventId] = useState();
  const [statusId, setStatusId] = useState();
  const [enforceFocusModal, setEnforceFocusModal] = useState(true)
  const [formAlert, setFormAlert] = useState({message: "", type: ""});

  function refreshPage() {
    window.location.reload(false);
  }

  const loadProductById = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/get_by_product/?product=${product.product_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setNist(response.data);
      })
      .catch();
  }, [product.product_id]);

  useEffect(() => {
    loadProductById();
  }, [loadProductById]);

  const loadFileByCrid = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=nist&file_type_id=${nist.crid}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setNistFiles(response.data?.filter(file => file.active === true));
      })
      .catch((error) => console.log("Error getting nist files", error));
  };

  useEffect(() => {
    if(product?.nist_required && nist.crid){
      loadFileByCrid();
    }
  }, [nist]);

  useEffect(() => {
    const psd =
      protectionProfiles.filter((pp) => pp.pp.tech_type === "Peripheral Sharing Devices").length >
      0;
    setIsPsd(psd);
  }, [protectionProfiles]);

  const loadFileByPPFileSource = useCallback(() => {
    if (progressPoint.progress_point === 6 || progressPoint.progress_point === 7) {
      if (progressPoint.progress_point === 7) {
        setCheckoutFiles(allFiles.filter((f) => f.file_source_type?.includes("Evaluator Check Out") || f.file_source_type?.includes("Validator Check Out")));
      } else {
        setCheckoutFiles(allFiles)
      }
    } else {
      axios
      .get(
        `${
          process.env.REACT_APP_DJANGO_ENDPOINT
        }file/get_files_by_type_and_file_source_type_and_type_id/?file_type=product&file_source_type=${
          progressPointInfo[progressPoint.progress_point]?.fileSourceType
        }&file_type_id=${product.product_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setFiles(response.data);
      })
      .catch((error) => {
        setFiles([]);
      });
    }
  }, [allFiles, authToken, isEdit, product.product_id, readOnly]);

  const pullOriginalFiles = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_files_by_type_and_file_source_type_and_type_id/?file_type=product&file_source_type=Check In&file_type_id=${product.product_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setOriginalFiles(response.data);
      })
      .catch((error) => {
        console.log("Error getting original files");
      });
  }, [authToken, product.product_id]);

  useEffect(() => {
    if (progressPoint) {
      pullOriginalFiles();
      setFiles([]);
      setCheckoutFiles([]);
      loadFileByPPFileSource();
    }
  }, [progressPoint, pullOriginalFiles, show]);

  useEffect(() => {
    if (progressPoint?.est_completion_dates) {
      let dates = [];
      for (let date in progressPoint?.est_completion_dates) {
        dates.push(progressPoint?.est_completion_dates[date]?.id);
      }
      setCompletionDateIds(dates);
    }
    if (progressPoint?.submission_dates) {
      let dates = [];
      for (let date in progressPoint?.submission_dates) {
        dates.push(progressPoint?.submission_dates[date]?.id);
      }
      setSubmissionDateIds(dates);
    }
  }, [show]);

  const handleChange = (e) => {
    if (e.target.name === "file") {
      if (progressPoint.progress_point === 6 || progressPoint.progress_point === 7) {
        const newFilesArr = newCheckoutFiles ? newCheckoutFiles : [];
        const newCheckoutFilesArr = checkoutFiles ? checkoutFiles : [];
        const file = {
          file_type: "product",
          file_source_type: progressPointInfo[progressPoint.progress_point]?.fileSourceType,
          file_display_name: formInfo.file_display_name,
          uploaded_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          file_name: e.target.files[0].name,
          file_label: formInfo.file_label,
          active: true,
          file: e.target.files[0],
          private: formInfo.file_public_status === "Public" ? false : true,
        };
        newFilesArr.push(file);
        newCheckoutFilesArr.push(file);
        setNewCheckoutFiles([...newFilesArr]);
        setCheckoutFiles([...newCheckoutFilesArr]);
      } else {
        const newFiles = files ? files : [];
        newFiles.push({
          file_type: "product",
          file_source_type: progressPointInfo[progressPoint.progress_point]?.fileSourceType,
          file_display_name: formInfo.file_display_name,
          uploaded_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          file_name: e.target.files[0].name,
          file_label: formInfo.file_label,
          active: true,
          file: e.target.files[0],
          private: formInfo.file_public_status === "Public" ? false : true,
        });
        setFiles([...newFiles]);
      }
    } else if (e.target.name === "nist_file") {
      if (progressPoint.progress_point === 6) {
        const nistFilesArr = nistFiles ? nistFiles : [];
        const newNistFilesArr = newNistFiles ? newNistFiles : [];
        const nistFile = {
          file_type: "nist",
          file_source_type: "Certificate Review",
          file_display_name: formInfo.nist_file_display_name,
          uploaded_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          file_name: e.target.files[0].name,
          file_label: formInfo.nist_file_label,
          active: true,
          file: e.target.files[0],
          private: formInfo.nist_file_public_status === "Public" ? false : true,
        };
        nistFilesArr.push(nistFile);
        newNistFilesArr.push(nistFile);
        setNistFiles([...nistFilesArr]);
        setNewNistFiles([...newNistFilesArr]);
      }
    } else {
      if (e.target.name === "file_display_name") {
        setEditedFileLabel(e.target.value);
        setFormInfo({
          ...formInfo,
          [e.target.name]: e.target.value,
          file_label: e.target.value,
        });
      } else if (e.target.name === "file_label") {
        setEditedFileLabel(e.target.value);
        setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
      } else if (e.target.name === "nist_file_display_name") {
        setNistEditedFileLabel(e.target.value);
        setFormInfo({
          ...formInfo,
          [e.target.name]: e.target.value,
          nist_file_label: e.target.value,
        });
      } else if (e.target.name === "nist_file_label") {
        setNistEditedFileLabel(e.target.value);
        setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
      } else {
        setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
      }
    }
  };

  const handleCheckboxReset = () => {
    const get = document.getElementsByName("files");

    for (var i = 0; i < get.length; i++) {
      get[i].checked = false;
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormInfo({});
    setFiles([]);
    handleCheckboxReset();
  };

  const removeFile = async (fileInfo, idx) => {
    if (fileInfo.file_id) {
      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${fileInfo.file_id}/`,
          {
            active: false,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then((response) => {
          if(fileInfo?.file_type === "nist"){
            loadFileByCrid();
          } else {
            loadFileByPPFileSource();
          }
        })
        .catch((error) => console.log("Unable to update file", error));
    } else {
      if(fileInfo?.file_type === "nist"){
        const newFiles = nistFiles.slice();
        newFiles.splice(idx, 1);  
        setNistFiles(newFiles);
      } else {
        const newFiles = files.slice();
        newFiles.splice(idx, 1);
        setFiles(newFiles);
      }
    }
  };

  const handleAddCheckoutFile = (e, checkoutFile) => {
    if (
      checkoutFile.file_source_type?.includes(progressPointInfo[progressPoint.progress_point].fileSourceType)
    ) {
      handleEditCheckoutFile(e, checkoutFile);
    } else if (e.target.checked) {
      const newFilesArr = newCheckoutFiles ? newCheckoutFiles : [];
      newFilesArr.push(checkoutFile);
      setNewCheckoutFiles(newFilesArr);
    } else if (!e.target.checked) {
      let fileIdx = newCheckoutFiles.findIndex((f) => f.file_id === checkoutFile.file_id);
      const newFiles = newCheckoutFiles.slice();
      newFiles.splice(fileIdx, 1);
      setNewCheckoutFiles(newFiles);
    }
  };

  const handleEditCheckoutFile = (e, checkoutFile) => {
    if(checkoutFile?.file_id){
      if (e.target.checked) {
        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${checkoutFile.file_id}/`,
            {
              file_source_type: progressPointInfo[progressPoint.progress_point].fileSourceType,
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            }
          )
          .then(() => console.log("updated active to true"))
          .catch((error) => console.log(error));
      } else if (!e.target.checked) {
        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${checkoutFile.file_id}/`,
            {
              file_source_type: "Other",
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            }
          )
          .then(() => console.log("updated active to false"))
          .catch((error) => console.log(error));
      }
    }
    return;
  };

  const handlePostCheckoutFile = () => {
    newCheckoutFiles.forEach((file) => {
      if (file.active === true) {
        delete file["uploaded_by"];
        if (!file.file_id) {
          const submittedFile = new FormData();
          submittedFile.append("file_type", "product");
          submittedFile.append("file_type_id", product.product_id);
          submittedFile.append("file", file.file);
          submittedFile.append("file_display_name", file.file_display_name);
          submittedFile.append("file_name", file.file_name);
          submittedFile.append("file_label", file.file_label);
          submittedFile.append("file_location", "uploads");
          submittedFile.append("file_mime_type", file?.file?.type);
          submittedFile.append("uploaded_on", new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())).toISOString());
          submittedFile.append("uploaded_by", user.id);
          submittedFile.append(
            "file_source_type",
            progressPointInfo[progressPoint.progress_point].fileSourceType
          );
          submittedFile.append("active", JSON.stringify(file.active));
          submittedFile.append("private", JSON.stringify(file.private));
          axios
            .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/`, submittedFile, {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => console.log("added checkout files"))
            .catch((error) => console.log(error));
        } else {
          //if the file_source_type hasn't already been updated to checkout
          if(!(file.file_source_type?.includes(progressPointInfo[progressPoint.progress_point].fileSourceType))){
            axios
            .put(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${file.file_id}/`,
              {
                file_source_type: `${file.file_source_type},${progressPointInfo[progressPoint.progress_point].fileSourceType}`,
              },
              {
                withCredentials: true,
                headers: {
                  Authorization: `Token ${authToken}`,
                  'X-CSRFToken': csrfToken
                },
              }
            )
            .then((response) => {
              
            })
            .catch((error) => {
              console.log("Error");
            });
          }
        }
      }
    });
  };

  const downloadFile = (file) => {
    setOverlayText("Downloading")
    setOverlayActive(true)
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        setOverlayActive(false)
        FileDownload(response.data, file.file_new_name ? file.file_new_name : file.file_name);
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  const handleAddNote = () => {
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}note/`,
        {
          note: formInfo?.notes,
          note_type: "product",
          note_type_id: product?.product_id,
          submitted_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          submitted_by: currentUser.id,
          progress_point: progressPoint.progress_point,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => setRefetchNotes(response.data.note_id))
      .catch((error) => console.log("Unable to add note: " + error));
  };

  const handleAlert = (validatorAlert, rejectionAlert) => {
    let subject = "";
    let recipients = {};
    if(validatorAlert){
        subject = `Check-out Package Uploaded to VID ${product?.v_id} in the Project File Area`
        recipients = {to: ["Validators"], cc: []}
    } 
    // Waiting on this per Heather request in v3 notficiations doc
    // else if (rejectionAlert) {
    //     subject = `Check-Out package for VID${product?.v_id} was rejected.`;
    //     recipients = {to: ["Lab"], cc: ["Validators"]}
    // }
    handleAlertAndMailer(
      csrfToken, 
      authToken, 
      {
        alert_type_id: product?.product_id,
        alert_type: "Product",
        subject: subject,
        recipients: recipients,
        alert_text: subject
      }
    )
  };

  const handleAddNist = () => {
    const nistData = new FormData();

    nistData.append("pid", product?.product_id);
    nistData.append("reqd", true);
    nistData.append("due", moment().add(160, "days").toJSON());
    if (newNistFiles.length > 0) {
      for (let f in newNistFiles) {
        if (!newNistFiles[f].file_mime_type) {
          nistData.append("attachments_file", newNistFiles[f].file);
          nistData.append("attachments_source_type", newNistFiles[f].file_source_type);
          nistData.append("attachments_type", newNistFiles[f].file_display_name);
          nistData.append("attachments_uploaded", newNistFiles[f].uploaded_on);

          nistData.append("private", newNistFiles[f].private);
          nistData.append("file_label", newNistFiles[f].file_label);
        }
      }
    }
    if (!nist.crid) {
      axios
        .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}nist/`, nistData, {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          refetch();
        })
        .catch((error) => console.log("Unable to submit new product: ", error));
    } else {
      axios
        .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}nist/${nist.crid}/`, nistData, {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          refetch();
        })
        .catch((error) => console.log("Unable to submit new product: ", error));
    }
  };

  const handleCheckNistFileAdded = () => {
    if (newNistFiles?.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const handleApprove = async (action) => {
    const approveData = new FormData();

    if (formInfo.nist_required === "true" || product?.nist_required) {
      handleCheckNistFileAdded()
      if (!handleCheckNistFileAdded()) {
        setFormAlert({message: "NIST Certificate Review selected as required. Please select NIST Review file.", type: "danger"});
        return;
      } else {
        setFormAlert({message: "NIST Review file successfully added!", type: "success"});
      }
    }

    let statusString = "";
    if (action === "rejected") {
      statusString = "Rejected";
      // handleAlert(false, true);
    } else if (action === "approved") {
      statusString = "Completed";
      // handleAlert(true, false);
    } else if (action === "submitting") {
      if (progressPoint?.status === "In Progress") {
        statusString = "Awaiting Checkout";
      } else {
        statusString = "Resubmitted";
      }
      // handleAlert(true, false);
    } else if (action === "Awaiting Checkout") {
      statusString = "Awaiting Checkout";
      handleAlert(true, false);
    } else {
      statusString = "In Progress";
      // handleAlert(true, false);
    }
    let resubmissionCounter = progressPoint.resubmission_counter;
    if (statusString === "Rejected") {
      if (resubmissionCounter === null) {
        resubmissionCounter = 0;
      } else {
        resubmissionCounter += 1;
      }
    }
    // if(progressPoint.progress_point === 6 || progressPoint.progress_point ===7){
    //   if(formInfo?.completion_date){
    //     createCalendarEvent( new Date(`${formInfo?.completion_date}T00:00`) )
    //   }

    // }

    // Sazhelle: Wanted to keep the same FormData() but with the setProduct()
    // This is going to allow the rest of the "default" in the product to stay the original intended Value
    const updatedProduct = { ...product }; // Creates a copy of the original product
    // Appends from the product form
    if (progressPoint.progress_point === 6 && formInfo.nist_required) {
      //IF Changed
      approveData.append("nist_required", formInfo.nist_required);
    }
    approveData.append(
      `${progressPointInfo[progressPoint.progress_point].completionDate}`,
      formInfo?.completion_date
        ? formInfo?.completion_date
        : new Date(product[progressPointInfo[progressPoint.progress_point].completionDate]).toJSON()
    );
    // Goes through the approve data and changes the Key in the product
    for (const [key, value] of approveData.entries()) {
      updatedProduct[key] = value;
    }
    // Use a Use state to change the original product with the copy so the states get changed throughout the
    // components
    setProduct(updatedProduct);
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product.product_id}/`,
        updatedProduct,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (formInfo.nist_required === "true" || product?.nist_required) {
          handleAddNist();
        }

        let data = {
          product: product.product_id,
          progress_point: progressPoint.progress_point,
          status: statusString,
          checkout_rejected_rationale:
            statusString === "Rejected" ? formInfo?.checkout_rejected_rationale : null,
          resubmission_counter: resubmissionCounter,
          est_completion_dates: completionDateIds,
          submission_dates: submissionDateIds,
        }

        const newDateObjects = Object.keys(formInfo).filter(key => key.includes("new_act_completion") || key.includes("new_est_completion"));

    
        if (newDateObjects.length > 0) {
          data["new_dates"] = newDateObjects.map(key => ({
            [key.split('_')[0]]: formInfo[key]
          }));
        }

        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
            data,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            }
          )
          .then((response) => {
            if (response.data.status === "Update Already Exists") {
              handleClose(product, action);
              refetch();
            } else {
              if (formInfo.notes) {
                handleAddNote();
              }
              if (newCheckoutFiles.length > 0) {
                handlePostCheckoutFile();
              }
              if (action === "Awaiting Checkout" || action === "submitting") {
                //Initializes PP7 when submitting or resubmiting pp6
                axios
                  .put(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
                    {
                      product: product.product_id,
                      progress_point: 7,
                      status: "In Progress",
                    },
                    {
                      withCredentials: true,
                      headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                      },
                    }
                  )
                  .then((response) => refetch())
                  .catch((error) => console.log(error));
              }
              setFiles([]);
              handleClose(product, action);
              refetch();
            }
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log("Unable to update product: ", error));
  };

  const updateProduct = async () => {
    const submittedData = new FormData();
    submittedData.append("is_resubmission", product.isResubmission || "");
    submittedData.append("ear_required", product.ear_required || "");
    submittedData.append("ear_previously_submitted", product.ear_previously_submitted || "");
    submittedData.append("nist_required", product.nist_required || "");
    submittedData.append("sync_required", product.sync_required || "");
    submittedData.append("post_certificate", product.post_certificate || "");
    if (formInfo.completion_date) {
      submittedData.append(
        `${progressPointInfo[progressPoint.progress_point].completionDate}`,
        new Date(formInfo?.completion_date).toJSON()
      );
      if (progressPoint.progress_point === 6 || progressPoint.progress_point === 7) {
        createCalendarEvent(new Date(`${formInfo?.completion_date}T00:00`));
      }
    } else {
      submittedData.append(
        `${progressPointInfo[progressPoint.progress_point].completionDate}`,
        new Date(product[progressPointInfo[progressPoint.progress_point].completionDate]).toJSON()
      );
    }

    if (progressPoint.progress_point === 6 && formInfo.nist_required) {
      submittedData.append("nist_required", formInfo.nist_required);
    }

    if (files.length > 0) {
      for (let f in files) {
        if (!files[f].file_mime_type) {
          submittedData.append("attachments_file", files[f].file);
          submittedData.append("attachments_source_type", files[f].file_source_type);
          submittedData.append("attachments_type", files[f].file_display_name);
          submittedData.append("attachments_uploaded", files[f].uploaded_on);

          submittedData.append("private", files[f].private);
          submittedData.append("file_label", files[f].file_label);
        }
      }
    }
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product.product_id}/`,
        submittedData,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (formInfo.notes) {
          handleAddNote();
        }
        if (newCheckoutFiles.length > 0) {
          handlePostCheckoutFile();
        }
        setFiles([]);
        handleClose(response.data);
        refetch();
      })
      .catch((error) => console.log("Unable to submit new product: ", error));

    if (formInfo.nist_required === "true" || product?.nist_required) {
      handleAddNist();
    }
  };

  const updateProgressPoint = async (combinedSave) => {
    let data = {
      product: product.product_id,
      progress_point: progressPoint.progress_point,
    };

    if (combinedSave) {
      data["status"] = "Completed";
      data["new_submission_date"] = true;
    } else if (!isEdit) {
      data["status"] = "In Progress";
    }

    if (
      progressPoint.progress_point === 6 &&
      formInfo.checkout_rejected_rationale &&
      permissions.role_type === "Validator"
    ) {
      data["checkout_rejected_rationale"] = formInfo.checkout_rejected_rationale;
    }

    const newDateObjects = Object.keys(formInfo).filter(key => key.includes("new_act_completion") || key.includes("new_est_completion"));

    
    if (newDateObjects.length > 0) {
      data["new_dates"] = newDateObjects.map(key => ({
        [key.split('_')[0]]: formInfo[key]
      }));
    }

    await axios
      .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`, data, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        console.log("progress updated");
      })
      .catch((error) => console.log(error));
  };

  const handleSubmit = (e, combinedSave, isEditSave) => {
    e.preventDefault();
    //we only want to update product if just saving on edit
    //else we go ahead and update progress point and product
    if (isEditSave && isEdit) {
      updateProduct();
      if (Object.keys(formInfo).some(key => key.includes("new_act_completion") || key.includes("new_est_completion"))) {
        updateProgressPoint(combinedSave);
      }
    } else {
      updateProgressPoint(combinedSave);
      updateProduct();
    }
  };

  useEffect(() => {
    if (
      product?.product_description?.length >= 50 &&
      product?.security_evaluation_summary?.length >= 50 &&
      product?.environmental_strengths?.length >= 50 &&
      product?.evaluation_configuration?.length >= 50
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [product]);

  const createCalendarEvent = (startDate) => {
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(startDate);
    endDate.setHours(23, 59, 59, 999);
    const title = `VID${product?.v_id} ${
      progressPointInfo[progressPoint.progress_point]?.title
    } Estimated Completion`;
    const requestData = {
      title: title,
      startDate: startDate,
      endDate: endDate,
      statusId: statusId,
      allDay: true,
      productId: product?.product_id,
    };

    if (eventId) {
      //Checks if event already exists
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/${eventId}/`,
          {
            startDate: startDate,
            endDate: endDate,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          console.log(response.status);
        })
        .catch((error) => console.log("Unable to update Calendar event: ", error));
    } else {
      axios
        .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/`, requestData, {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.status);
        })
        .catch((error) => console.log("Unable to create Calendar event: ", error));
    }
  };

  useEffect(() => {
    if (progressPoint.progress_point === 6 || progressPoint.progress_point === 7) {
      axios
        .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/status`, {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        })
        .then((response) => {
          let currStatus = response.data?.filter((status) =>
            status.text.includes(progressPoint.progress_point)
          );
          setStatusId(currStatus[0]?.id);
          axios
            .get(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/status/get_event_by_status_and_product/?statusId=${currStatus[0]?.id}&productId=${product?.product_id}`,
              {
                withCredentials: true,
                headers: {
                  Authorization: `Token ${authToken}`,
                },
              }
            )
            .then((response) => {
              setEventId(response.data.id);
            })
            .catch((error) => console.log("Unable to get Calendar Event: ", error));
        })
        .catch((error) => console.log("Unable to get Calendar statuses: ", error));
    }
  }, [progressPoint.progressPoint]);

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          handleClose();
        }}
        enforceFocus={enforceFocusModal}
      >
        <Form
          onSubmit={(e) => handleSubmit(e, false, false)}
          onReset={handleReset}
          id="progress-point-form"
        >
          <Modal.Header className="border-0" closeButton>
            <Modal.Title>
              <h3 className="text-bright-navy">
                {progressPoint.progress_point === 6 &&
                  isValidator &&
                  permissions.role_type === "Validator" &&
                  "VALIDATOR REVIEWS "}
                {progressPoint.progress_point !== 6
                  ? progressPointInfo[progressPoint.progress_point]?.title
                  : ` ${progressPointInfo[progressPoint.progress_point]?.title}`}
                {isEdit ? (
                  " EDIT"
                ) : ["Not Started", "Est. Completion Date Set"]?.includes(progressPoint?.status) ? (
                  " INITIATE"
                ) : progressPoint?.status === "In Progress" ? (
                  " COMPLETE"
                ) : progressPoint?.progress_point === 6 ? (
                  " Check-Out"
                ) : (
                  <></>
                )}{" "}
                for VID: {product.v_id}
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <Row className="mt-3">
                    <Col sm={5} mb={2}>
                      <Form.Group controlId="completion_date">
                        <Form.Label className="fw-bold">Current Est. Completion Date:</Form.Label>
                        <Form.Control
                          className="mb-3"
                          type="date"
                          name="completion_date"
                          onChange={handleChange}
                          plaintext={readOnly}
                          readOnly={readOnly}
                          defaultValue={moment
                            .utc(
                              product[
                                progressPointInfo[progressPoint.progress_point]?.completionDate
                              ]
                            )
                            .format("YYYY-MM-DD")}
                        />
                      </Form.Group>
                      {
                        (progressPoint?.progress_point === 7 || 
                        progressPoint?.progress_point === 6) && 
                        progressPoint?.est_completion_dates?.map((date, idx) => (
                        <Row key={idx}>
                          <Col>
                            <Form.Group controlId="completion_date">
                            <Form.Label className="fw-bold">                        
                              {idx === 0
                                  ? `${progressPoint?.progress_point}. Submission`
                                  : `${progressPoint?.progress_point}${String.fromCharCode(64 + idx).toLowerCase()}. Resubmission`}
                            </Form.Label>
                            <Stack direction="horizontal">
                              <p className="mx-2">Estimated:</p>
                              <Form.Control
                                className="mb-3"
                                type="date"
                                name={`${completionDateIds[idx]}_new_est_completion`}
                                onChange={handleChange}
                                plaintext={readOnly}
                                readOnly={readOnly}
                                defaultValue={moment.utc(date?.estimated_date).format("YYYY-MM-DD")}
                              />
                            </Stack>
                            {progressPoint?.submission_dates[idx] &&
                            <Stack direction="horizontal">
                              <p className="mx-2">Actual:</p>
                              <Form.Control
                                className="mb-3"
                                type="date"
                                name={`${submissionDateIds[idx]}_new_act_completion`}
                                onChange={handleChange}
                                plaintext={readOnly}
                                readOnly={readOnly}
                                defaultValue={moment
                                  .utc(progressPoint?.submission_dates[idx]?.estimated_date)
                                  .format("YYYY-MM-DD")
                                }
                              />
                            </Stack>
                          }
                          </Form.Group>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                  {progressPoint.progress_point === 6 && (
                    <Form.Group controlId="nist_reqd" className="mb-3">
                      <Form.Label>
                        NIST Certificate Review ({isPsd ? "Required" : "Optional"} for selected
                        technology types)
                      </Form.Label>
                      <Row>
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            name="nist_required"
                            label="Required, Please Select Files Below"
                            value={true}
                            onChange={handleChange}
                            defaultChecked={product?.nist_required}
                            id="nist_required1"
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            name="nist_required"
                            label="No NIST Review Needed"
                            value={false}
                            onChange={handleChange}
                            defaultChecked={!product?.nist_required}
                            id="nist_required2"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  )}
                  {progressPoint.progress_point === 6 &&
                  permissions.role_type === "Lab" &&
                  (product?.product_description?.length < 50 ||
                    product?.security_evaluation_summary?.length < 50 ||
                    product?.environmental_strengths?.length < 50 ||
                    product?.evaluation_configuration?.length < 50) ? (
                    <Row className="mt-4 mb-4">
                      <Col>
                        <p>Please Address the following Sections on the Product Details Page:</p>
                        <div className="text-danger">
                          {product?.product_description?.length < 50 && <p>Product Description</p>}

                          {product?.security_evaluation_summary?.length < 50 && (
                            <p>Security Evaluation Summary </p>
                          )}

                          {product?.environmental_strengths?.length < 50 && (
                            <p>Environmental Strengths</p>
                          )}

                          {product?.evaluation_configuration?.length < 50 && (
                            <p>Evaluation Configuration</p>
                          )}
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  <Row>
                    {progressPoint.progress_point === 6 &&
                      (formInfo.nist_required === "true" || product.nist_required) && (
                        <>
                          <h6>NIST Review Files</h6>
                          <Form.Text className="text-muted fst-italic">Required for Certificate Review</Form.Text>
                          <Col xl={3} sm={12}>
                            <Form.Group
                              className="mb-4 text-start"
                              controlId="nist_file_display_name"
                            >
                              <Form.Label className="small text-secondary">Type</Form.Label>
                              <Form.Select name="nist_file_display_name" onChange={handleChange}>
                                <option value=""></option>
                                {progressPointInfo[progressPoint.progress_point]?.fileTypes.map(
                                  (type, idx) => (
                                    <option value={type} key={idx}>
                                      {type}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col xl={3} sm={12}>
                            <Form.Group
                              className="mb-4 text-start"
                              controlId="nist_file_public_status"
                            >
                              <Form.Label className="small text-secondary">
                                Document Visibility
                              </Form.Label>
                              <Form.Select name="nist_file_public_status" onChange={handleChange}>
                                <option value=""></option>
                                <option value="Public">Public</option>
                                <option value="Proprietary">Proprietary</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col xl={6} sm={12}>
                            <Form.Group controlId="nist_file_label">
                              <Form.Label className="small text-secondary">File Label</Form.Label>
                              <Form.Control
                                value={nistEditedFileLabel}
                                type="text"
                                name="nist_file_label"
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xl={6} sm={12} className="mb-3">
                            <Form.Group controlId="nist_file">
                              <Form.Label className="small text-secondary">Attach Files</Form.Label>
                              <Form.Control
                                type="file"
                                name="nist_file"
                                onChange={handleChange}
                                disabled={
                                  !formInfo.nist_file_public_status ||
                                  !formInfo.nist_file_display_name
                                }
                              />
                            </Form.Group>
                          </Col>
                        </>
                      )}
                  </Row>
                  <Row className="mb-3">
                    <Col lg={10}>
                      {nistFiles?.length > 0 && progressPoint.progress_point === 6 && (
                        <div className="border">
                          <p className="mx-3 mt-2 fw-bold">Attached Files</p>
                          {nistFiles
                            .filter((file) => file?.active === true)
                            ?.map((file, idx) => (
                              <Row className="m-3 d-flex justify-content-around" key={idx}>
                                <Col sm={4}>{file.file_display_name}</Col>
                                <Col sm={4}>{file.file_label}</Col>
                                <Col sm={2}>{file.private ? "Proprietary" : "Public"}</Col>
                                {!readOnly && (
                                  <Col sm={1} className="d-flex justify-content-center">
                                    <Button
                                      variant="outline-primary"
                                      className="attachment-remove"
                                      onClick={() => removeFile(file, idx)}
                                    >
                                      X
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            ))}
                        </div>
                      )}
                    </Col>
                  </Row>

                  {files.length > 0 && (
                    <Row>
                      {readOnly && <p className="fw-bold form-title mt-3 mb-1">ATTACHED FILES</p>}
                      {!readOnly && <p className="fw-bold form-title mt-3 mb-1">ATTACH FILES</p>}
                    </Row>
                  )}
                  {progressPoint.progress_point !== 8 && (
                    <>
                      <Row>
                        {!readOnly && (
                          <>
                            <h6>Progress Point Files</h6>
                            <Col xl={3} sm={12}>
                              <Form.Group className="mb-4 text-start" controlId="file_display_name">
                                <Form.Label className="small text-secondary">Type</Form.Label>
                                <Form.Select name="file_display_name" onChange={handleChange}>
                                  <option value=""></option>
                                  {progressPointInfo[progressPoint.progress_point]?.fileTypes.map(
                                    (type, idx) => (
                                      <option value={type} key={idx}>
                                        {type}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col xl={3} sm={12}>
                              <Form.Group
                                className="mb-4 text-start"
                                controlId="file_public_status"
                              >
                                <Form.Label className="small text-secondary">
                                  Document Visibility
                                </Form.Label>
                                <Form.Select name="file_public_status" onChange={handleChange}>
                                  <option value=""></option>
                                  <option value="Public">Public</option>
                                  <option value="Proprietary">Proprietary</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col xl={6} sm={12}>
                              <Form.Group controlId="file_label">
                                <Form.Label className="small text-secondary">File Label</Form.Label>
                                <Form.Control
                                  value={editedFileLabel}
                                  type="text"
                                  name="file_label"
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </Col>
                            <Col xl={6} sm={12} className="mb-3">
                              <Form.Group controlId="file">
                                <Form.Label className="small text-secondary">
                                  Attach Files
                                </Form.Label>
                                <Form.Control
                                  type="file"
                                  name="file"
                                  onChange={handleChange}
                                  disabled={
                                    !formInfo.file_public_status || !formInfo.file_display_name
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col lg={10}>
                          {files?.length > 0 && (
                            <div className="border">
                              <p className="mx-3 mt-2 fw-bold">Attached Files</p>
                              {files
                                .filter((file) => file?.active === true)
                                ?.map((file, idx) => (
                                  <Row className="m-3 d-flex justify-content-around" key={idx}>
                                    <Col sm={4}>{file.file_display_name}</Col>
                                    <Col sm={4}>{file.file_label}</Col>
                                    <Col sm={2}>{file.private ? "Proprietary" : "Public"}</Col>
                                    {!readOnly && (
                                      <Col sm={1} className="d-flex justify-content-center">
                                        <Button
                                          variant="outline-primary"
                                          className="attachment-remove"
                                          onClick={() => removeFile(file, idx)}
                                        >
                                          X
                                        </Button>
                                      </Col>
                                    )}
                                  </Row>
                                ))}
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        {progressPoint.progress_point !== 6 &&
                        progressPoint.progress_point !== 7 ? (
                          <Col lg={10}>
                            {originalFiles?.length > 0 && (
                              <div className="mt-5">
                                <p className="fw-bold form-title mb-1">ORIGINAL ATTACHED FILES:</p>
                                <div className="border">
                                  <p className="mx-3 mt-2 fw-bold">Attached Files</p>
                                  {originalFiles?.map((file, idx) => (
                                    <Row className="m-3 d-flex justify-content-around" key={idx}>
                                      <Col sm={4}>{file.file_display_name}</Col>
                                      <Col sm={4}>{file.file_label}</Col>
                                      <Col sm={2}>{file.private ? "Proprietary" : "Public"}</Col>
                                    </Row>
                                  ))}
                                </div>
                              </div>
                            )}
                          </Col>
                        ) : (
                          <Col lg={12}>
                            <div className="mt-5">
                              <p className="fw-bold form-title mb-1">
                                FILES FOR CHECK-OUT PACKAGE:
                              </p>
                              <div className="border">
                                <p className="mx-3 mt-2 fw-bold">Attached Files</p>
                                {/* only show checkout files for evaluator or validator */}
                                {checkoutFiles?.map((file, idx) => (
                                  <Row className="m-3" key={idx}>
                                    {!readOnly ? (
                                      <Col>
                                        <Form.Check
                                          type="checkbox"
                                          id={`${file.file_id}`}
                                          name="files"
                                          className="progress-point-checkboxes"
                                          defaultChecked={
                                            file?.file_source_type?.includes(
                                            progressPointInfo[progressPoint.progress_point]
                                              .fileSourceType
                                            )
                                          }
                                          onClick={(e) => handleAddCheckoutFile(e, file)}
                                          label={
                                            <Row>
                                              <Col lg={3}>
                                                <Button
                                                  variant="link"
                                                  className="text-bright-navy text-decoration-underline p-0"
                                                  style={{
                                                    wordBreak: "break-word",
                                                    textAlign: "left",
                                                  }}
                                                  onClick={() => downloadFile(file)}
                                                >
                                                  {file.file_label ? file.file_label : file.file_display_name}
                                                </Button>
                                              </Col>
                                              <Col lg={3}>{file.file_name}</Col>
                                              <Col lg={3}>{file?.uploaded_on && moment.utc(file.uploaded_on).format("YYYY.MM.DD [at] HH:mm")}</Col>
                                              <Col lg={3}>
                                                {file.private ? "Proprietary" : "Public"}
                                              </Col>
                                            </Row>
                                          }
                                        />
                                      </Col>
                                    ) : (
                                      <Row>
                                        <Col lg={3}>
                                          <Button
                                            variant="link"
                                            className="text-bright-navy text-decoration-underline p-0"
                                            style={{
                                              wordBreak: "break-word",
                                              textAlign: "left",
                                            }}
                                            onClick={() => downloadFile(file)}
                                          >
                                            {file.file_label ? file.file_label : file.file_display_name}
                                          </Button>
                                        </Col>
                                        <Col lg={3}>{file.file_name}</Col>
                                        <Col lg={3}>{file?.uploaded_on && moment.utc(file.uploaded_on).format("YYYY.MM.DD [at] HH:mm")}</Col>
                                        <Col lg={3}>
                                          {file.private ? "Proprietary" : "Public"}
                                        </Col>
                                      </Row>
                                    )}
                                  </Row>
                                ))}
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </>
                  )}
                  {!readOnly && (
                    <Row className="mt-5">
                      <Col>
                        <p className="fw-bold form-title mb-2">NOTES*</p>
                        <HTMLEditor
                          name="notes"
                          handleChange={handleChange}
                          setIsValid={() => {}}
                          isValid={true}
                          setEnforceFocusModal={setEnforceFocusModal}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Container>
            {formAlert?.message !== "" && 
              <Row>
                <Col sm={12}>
                  <Alert variant={formAlert.type}>{formAlert.message}</Alert>
                </Col>
              </Row>
            }
            {readOnly && (
              <>
                {isValidator &&
                permissions.role_type === "Validator" &&
                progressPoint.progress_point === 6 ? (
                  <></>
                ) : (
                  <Row>
                    <Col sm={12} md={2}>
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          setRejected(false);
                          handleClose();
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col sm={12} md={3}>
                      <Button variant="primary" onClick={updateProgress}>
                        Save/Complete
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            )}
            {!readOnly && (
              <Row className="d-flex justify-content-end">
                <Col sm={12} md={3} lg={2}>
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={() => {
                      setRejected(false);
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col sm={12} md={3} lg={2}>
                  <Button
                    variant="outline-primary"
                    onClick={(e) => handleSubmit(e, false, true)}
                    className="square-button"
                  >
                    Save
                  </Button>
                </Col>
                {progressPoint.progress_point === 6 &&
                  (permissions.role_type === "Lab" || permissions.role_type === "NIAP") && (
                    <>
                      {progressPoint.status === "Rejected" && permissions.role_type === "Lab" ? (
                        <Col sm={12} md={3}>
                          <Button
                            variant="primary"
                            onClick={() => handleApprove("submitting")}
                            className="square-button"
                          >
                            Resubmit
                          </Button>
                        </Col>
                      ) : progressPoint.status === "Amended Package Needed" ? (
                        <>
                          <Col sm={12} md={3}>
                            <Button
                              disabled={disableButton}
                              variant="success"
                              onClick={() => handleApprove("Awaiting Checkout")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Submit Changes
                            </Button>
                          </Col>
                        </>
                      ) : progressPoint.status !== "Resubmitted" &&
                        progressPoint.status !== "Awaiting Checkout" ? (
                        <>
                          <Col sm={12} md={5} lg={3}>
                            <Button
                              disabled={disableButton}
                              variant="success"
                              onClick={() => handleApprove("Awaiting Checkout")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Submit for Validation
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                {!isEdit &&
                  progressPoint.progress_point !== 6 &&
                  progressPoint.progress_point !== 7 && (
                    <Col sm={12} md={5} lg={2} className="me-3">
                      <Button
                        variant="primary"
                        type="submit"
                        className="square-button px-2"
                        onClick={(e) => handleSubmit(e, true, false)}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Save & Submit
                      </Button>
                    </Col>
                  )}
              </Row>
            )}
            {(rejected || progressPoint?.checkout_rejected_rationale) && (
              <Col lg={12} className="px-3">
                <Form.Group controlId="checkout_rejected_rationale">
                  <p className="fw-bold form-title">RATIONALE FOR REJECTION</p>
                  <Form.Control
                    rows={3}
                    as="textarea"
                    name="checkout_rejected_rationale"
                    defaultValue={progressPoint?.checkout_rejected_rationale}
                    disabled={permissions.role_type !== "Validator"}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            )}
            </Container>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
