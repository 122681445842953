import { useEffect, useCallback, useState, useRef } from "react";
import axios from "axios";
import { Card, Tab, Tabs } from "react-bootstrap";
import { useCookies } from "react-cookie";
import ProductsInEvalTable from "./ProductsInEvalTable";
import LabEarReviewTable from "./LabEarReviewTable";
import StageTracker from "../StageTracker/StageTracker";
import CCTLAvailableAMTable from "./CCTLAvailableAMTable";
import { useInterval } from "../../../hooks/useInterval";

export default function CCTLProductsModule({
  currentRole,
  amProjects,
  refetch,
  setAlertInfo,
  setOverlayActive,
  setOverlayText
}) {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const [projects, setProjects] = useState([]);
  const [inEvalProducts, setInEvalProducts] = useState([]);
  const [currentUserEars, setCurrentUserEars] = useState([]);
  const [progressPoints, setProgressPoints] = useState([]);
  const [projpps, setProjpps] = useState([]);
  const evalTableRef = useRef()
  const stageTableRef = useRef()
  const earTableRef = useRef()
  const amTableRef = useRef()

  const fetchInEvalProducts = useCallback(() => {
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/current_user_products/?status=In Progress`,
    { withCredentials: true, headers: { Authorization: `Token ${authToken}` }})
    .then(response => setInEvalProducts(response.data))
    .catch(error => console.log('Unable to get current products: ' + error));
  }, [])

  const fetchProjects = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/stage_tracker_products/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) =>
        setProjects(response.data)
      )
      .catch((error) => console.log(error));
  }, []);

  const fetchEars = useCallback(() => {
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/current_user_ears/`,
    { withCredentials: true, headers: { Authorization: `Token ${authToken}` }})
    .then(response => setCurrentUserEars(response.data))
    .catch(error => console.log('Unable to get current user ears: ' + error));
  }, [])

  const fetchGroupedPP = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/get_grouped_progress_points/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => setProgressPoints(response.data))
      .catch((error) =>
        console.log("Unable to get progress points: " + error)
      );
  }, [])

  const fetchGroupedProtectionProfiles = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_grouped_pp/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => {
        setProjpps(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])

  useEffect(() => {
    fetchInEvalProducts();
  }, [])

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    fetchEars();
  }, [])

  useEffect(() => {
    fetchGroupedProtectionProfiles();
  }, [])

  useEffect(() => {
    fetchGroupedPP();
  }, [])

  useInterval(() => {
    fetchInEvalProducts();
    if(evalTableRef.current) evalTableRef.current.onQueryChange()
    fetchProjects();
    if(stageTableRef.current) stageTableRef.current.onQueryChange()
    fetchEars();
    if(earTableRef.current) earTableRef.current.onQueryChange()
    fetchGroupedProtectionProfiles();
    fetchGroupedPP();
    if(amTableRef.current) amTableRef.current.onQueryChange()
  }, 300000); //5 min

  const reloadTables = () => {
    refetch()
    fetchInEvalProducts();
    if(evalTableRef.current) evalTableRef.current.onQueryChange()
    fetchProjects();
    if(stageTableRef.current) stageTableRef.current.onQueryChange()
    fetchEars();
    if(earTableRef.current) earTableRef.current.onQueryChange()
    fetchGroupedProtectionProfiles();
    fetchGroupedPP();
    if(amTableRef.current) amTableRef.current.onQueryChange()
  }

  return (
    <Card>
      <Card.Body>
        <Tabs defaultActiveKey="my products in eval" className="mb-3">
          <Tab
            eventKey="my products in eval"
            title={
              <div className="d-flex align-items-center">
               Products Management List
                <h4 className="text-primary ms-2 my-0 fw-bold">
                  {inEvalProducts?.count}
                </h4>
              </div>
            }
          >
            <ProductsInEvalTable
              tableRef={evalTableRef}
              currentProductsInEval={inEvalProducts}
              progressPoints={progressPoints}
              currentRole={currentRole}
              refetch={reloadTables}
            />
          </Tab>
          <Tab
            eventKey="assurance maintenance"
            title={
              <div className="d-flex align-items-center">
                Products Available for AM
                <h4 className="text-primary ms-2 my-0 fw-bold">
                  {amProjects?.length}
                </h4>
              </div>
            }
          >
            <CCTLAvailableAMTable
              tableRef={amTableRef}
              productsInAM={amProjects}
              projpps={projpps}
              refetch={reloadTables}
              setAlertInfo={setAlertInfo}
              setOverlayActive={setOverlayActive} 
              setOverlayText={setOverlayText} 
            />
          </Tab>
          {/* {currentRole?.role_permissions?.find(
            (permission) => permission.area === "EAR Reviews"
          )?.read && (
            <Tab
              eventKey="ear reviews"
              title={
                <div className="d-flex align-items-center">
                  EAR Reviews
                  <h4 className="text-primary ms-2 my-0 fw-bold">
                    {currentUserEars?.count}
                  </h4>
                </div>
              }
            >
              <LabEarReviewTable tableRef={earTableRef} currentUserEars={currentUserEars} refetch={fetchEars} />
            </Tab>
          )} */}
          <Tab
            eventKey="projects"
            title={
              <div className="d-flex align-items-center">
                Project State Tracker
                <h4 className="text-primary ms-2 my-0 fw-bold">
                  {projects?.count}
                </h4>
              </div>
            }
          >
            <StageTracker tableRef={stageTableRef} projects={projects} isCCTL={true}/>
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  );
}
